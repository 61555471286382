import React from "react";
import ScrollToTopLink from "../scrollTopLink";
import StarRating from "../starts";

export const RenderEmpresa = ({ empresa }) => {
  return (
    <ScrollToTopLink
      to={`/empresa/${empresa.slug}`}
      title={"Clique para ver empresa"}
      className="gap-2 p-2 my-3 shadow-sm empresa text-decoration-none d-flex border-lightt rounded-1 empresa-card"
    >
      <img src={empresa.logo} className="logo-empresa" alt={empresa.nomeEmpresa} />
      <div className="de w-100 my-">
        <b>
          {empresa?.nomeEmpresa.split(" ").slice(0, 4).join(" ")}
          {empresa?.nomeEmpresa.split(" ").length > 3 ? " ..." : ""}
        </b>

        <div className="gap-2 d-flex">
          <StarRating rating={empresa.avaliacao} />
          <span className="text-secondary">{empresa.avverialiacao}</span>
        </div>
        <span className="text-secondary f-12">
          {empresa?.enderecoEmpresa.split(" ").slice(0, 3).join(" ")}
          {empresa?.enderecoEmpresa.split(" ").length > 3 ? " ..." : ""} - Angola
        </span>

        <div className="b-emp w-100">
          <span className="text-dark f-12">
            Categoria:{" "}
            <b className="fw-bolder"> {empresa.categoria}</b>
          </span>
          <div className="d-flex justify-content-between">
            <div className="justify-content-between w-full py-2 my-auto d-flex">
              <div className="gap-3 py-2 my-auto d-flex">
                {empresa.siteEmpresa.toLowerCase() !== "n/a" && (
                  <a target="__blank" href={`https://${empresa.siteEmpresa}`}>
                    <i className="bi bi-globe"></i>
                  </a>
                )}
                {empresa.emailEmpresa.toLowerCase() !== "n/a" && (
                  <a href={`mailto:${empresa.emailEmpresa}`}>
                    <i className="bi bi-envelope"></i>
                  </a>
                )}
                {empresa.whatsapp.toLowerCase() !== "n/a" && (
                  <a href={`tel:${empresa.whatsapp}`}>
                    <i className="bi bi-telephone"></i>
                  </a>
                )}
              </div>
              {empresa.selo && (
                <div className="my-auto text-end w-full">
                  <i className="bi ml-auto px-2 verified bi-shield-fill-check">
                    Verified
                  </i>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ScrollToTopLink>
  );
};
