import React from "react";
import ContentLoader from "react-content-loader";

const EmpresaLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={300}
      height={150}
      viewBox="0 0 300 150"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="10" y="10" rx="3" ry="3" width="80" height="80" />
      <rect x="100" y="15" rx="4" ry="4" width="180" height="15" />
      <rect x="100" y="40" rx="3" ry="3" width="120" height="10" />
      <rect x="100" y="60" rx="3" ry="3" width="150" height="10" />
      <rect x="100" y="80" rx="3" ry="3" width="60" height="10" />
      <rect x="10" y="100" rx="3" ry="3" width="280" height="10" />
      <rect x="10" y="120" rx="3" ry="3" width="280" height="10" />
    </ContentLoader>
  );
};

export default EmpresaLoader;
