import { Route, BrowserRouter, Routes } from "react-router-dom";
import Contactar from "./contactar";
import CriarConta from "./criar_conta";
import DetalheCurso from "./detalhe_curso";
import Home from "./home";
import Login from "./login";
import Politicas from "./politicas";
import { useEffect, useState } from "react";
import { UserProvider } from "./userContext";
import SubmitP from "./enviar_projecto";
import NotFoundPage from "./notfound";
import Faqs from "./faqs";
import Itens from "./itens_permitidos";
import Reembolso from "./reembolso";
import Seguro from "./seguro_aluguel";
import EnviarMensagem from "./enviar_mensagem";
import Mensagens from "./mensagens";
import Conversa from "./conversa";
import Perfil from "./perfil";
import Ranking from "./ranking";
import Descontos from "./descontos";
import Cadastro from "./cadastro";
import CadastroConsumidor from "./cadastroConsumidor";
import ReclamarBuscar from "./reclamarBusca";
import PerfilEmpresa from "./perfilEmpresa/perfilEmpresa";
import CadastroEmpresa from "./cadastroEmpresa/cadastroEmpresa";
import ReclamarEmpresa from "./reclamarEmpresa";
import CentralAjuda from "./centralAjuda";
import Produtos from "./produtos";
import Blog from "./blog";
import SolicitarCadastro from "./solicitarCadastro";
import LoginEmpresa from "./entrarEmpresa/entrarEmpresa";
import PerfilMinhaEmpresa from "./minhaEmpresa/perfilMinhaEmpresa";
import EmailSender from "./how-to-use-api";
import EmpresasPorCategoria from "./empresasPorCategoria/empresa-por-categoria";


const RotasPT = (props) => {

    const { emaill, setEmaill, nomee, setNomee, cart, adicionar, remover, handleClick } = props;

    return (
        <BrowserRouter>
            <UserProvider>
                <Routes >
                    <Route element={<EmpresasPorCategoria nomee={nomee} emaill={emaill} cart={cart} />} path="/empresas/categoria/:categoria" />
                    <Route element={<EnviarMensagem nomee={nomee} emaill={emaill} cart={cart} path="/enviar-mensagem/:uid" />} exact />
                    <Route element={<Home nomee={nomee} emaill={emaill} cart={cart} />} path="/" />
                    <Route element={<Mensagens nomee={nomee} emaill={emaill} cart={cart} />} path="/mensagens" />
                    <Route element={<EmailSender nomee={nomee} emaill={emaill} cart={cart} />} path="/how-to-use" />
                    <Route element={<Perfil nomee={nomee} emaill={emaill} cart={cart} />} path="/perfil" />
                    <Route element={<PerfilMinhaEmpresa nomee={nomee} emaill={emaill} cart={cart} />} path="/perfil/minha-empresa" />
                    <Route element={<Conversa nomee={nomee} emaill={emaill} cart={cart} />} path="/conversa/:uid" />
                    <Route element={<NotFoundPage nomee={nomee} emaill={emaill} cart={cart} />} />
                    <Route element={<Contactar nomee={nomee} emaill={emaill} cart={cart} />} path="/contactar" exact />
                    <Route element={<Ranking nomee={nomee} emaill={emaill} cart={cart} />} path="/ranking" exact />
                    <Route element={<ReclamarBuscar nomee={nomee} emaill={emaill} cart={cart} />} path="/reclamar" exact />
                    <Route element={<ReclamarEmpresa nomee={nomee} emaill={emaill} cart={cart} />} path="/reclamar/:empresa_slug" exact />
                    <Route element={<Produtos nomee={nomee} emaill={emaill} cart={cart} />} path="/empresa/produtos" exact />
                    <Route element={<PerfilEmpresa nomee={nomee} emaill={emaill} cart={cart} />} path="/empresa/:empresa_slug" exact />
                    <Route element={<Blog nomee={nomee} emaill={emaill} cart={cart} />} path="/blog/:blog" exact />
                    <Route element={<Descontos nomee={nomee} emaill={emaill} cart={cart} />} path="/descontos" exact />
                    <Route element={<Cadastro nomee={nomee} emaill={emaill} cart={cart} />} path="/cadastro" exact />
                    <Route element={<CentralAjuda nomee={nomee} emaill={emaill} cart={cart} />} path="/central-de-ajuda" exact />
                    <Route element={<CadastroConsumidor nomee={nomee} emaill={emaill} cart={cart} />} path="/cadastro/consumidor" exact />
                    <Route element={<CadastroEmpresa nomee={nomee} emaill={emaill} cart={cart} />} path="/cadastro/empresa" exact />
                    <Route element={<Faqs nomee={nomee} emaill={emaill} cart={cart} />} path="/faqs" exact />
                    <Route element={<Login cart={cart} emaill={emaill} setEmaill={setEmaill} nomee={nomee} setNomee={setNomee} />} path="/login" exact />
                    <Route element={<LoginEmpresa cart={cart} emaill={emaill} setEmaill={setEmaill} nomee={nomee} setNomee={setNomee} />} path="/login/empresa" exact />
                    <Route element={<SolicitarCadastro cart={cart} emaill={emaill} setEmaill={setEmaill} nomee={nomee} setNomee={setNomee} />} path="/solicitar-cadastro" exact />
                    <Route element={<NotFoundPage />} />
                    <Route element={<SubmitP nomee={nomee} emaill={emaill} cart={cart} handleClick={handleClick} />} path="/add-artigo" exact />
                    <Route element={<CriarConta />} path="/criar_conta" exact />
                    <Route element={<Politicas nomee={nomee} emaill={emaill} cart={cart} />} path="/politicas" exact />
                    <Route element={<Itens nomee={nomee} emaill={emaill} cart={cart} />} path="/itens" exact />
                    <Route element={<Seguro nomee={nomee} emaill={emaill} cart={cart} />} path="/seguro" exact />
                    <Route element={<Reembolso nomee={nomee} emaill={emaill} cart={cart} />} path="/reembolso" exact />
                    <Route element={<DetalheCurso nomee={nomee} emaill={emaill} cart={cart} />} path="/platform/equipement/daluguel/:id" exact />

                </Routes>
            </UserProvider>
        </BrowserRouter>
    )
}

export default RotasPT;