import React, { useState, useContext } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { v4 as uuidv4 } from "uuid";
import { UserContext } from "../userContext";
import "../../css/login.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Swal from "sweetalert2";
import { criarReclamacao } from "../../services/reclamacoes";
import { createEmpresa } from "../../services/eempresas";

const CadastroEmpresa = ({ setNomee, setEmaill, cart, nomee, emaill }) => {
  const { handleLogin, push } = useContext(UserContext);

  document.title = `Cadastrar Sua Empresa | Reputação 360`;
  const [user, setUser] = useState(null);
  const [load, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    emailEmpresa: "",
    numeroBI: "",
    siteEmpresa: "",
    whatsapp: "",
    enderecoEmpresa: "",
    sobre: "",
    nomeResponsavel: "",
    senha: "",
    categoria: "",
    tipo: "empresa",
    anexos: [],
    selo: false
  });


  const [logoFile, setLogoFile] = useState(null);
  const [capaFile, setCapaFile] = useState(null);

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setLogoFile(file);
  };

  const handleCapaChange = (e) => {
    const file = e.target.files[0];
    setCapaFile(file);
  };

  // Função auxiliar para obter o nome do mês
  const getMonthName = (monthIndex) => {
    const monthNames = [
      "jan",
      "fev",
      "mar",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "set",
      "out",
      "nov",
      "dez",
    ];
    return monthNames[monthIndex];
  };

  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()} de ${getMonthName(
    currentDate.getMonth()
  )} de ${currentDate.getFullYear()}`;
  const handleCadastroEmpresa = async () => {
    setLoading(true);
    try {
      const { emailEmpresa, senha } = formData;

      // Verificar se as imagens foram selecionadas
      if (!logoFile || !capaFile) {
        setLoading(false);
        Swal.fire({
          icon: "info",
          title: "Imagens não selecionadas!",
          text: "Por favor, selecione a logo e a capa da empresa.",
        });
        return;
      }

      // Upload de logo e capa para o Storage
      const logoFileName = `empresas/${uuidv4()}_${logoFile.name}`;
      const capaFileName = `empresas/${uuidv4()}_${capaFile.name}`;

      const storageRef = firebase.storage().ref();

      // Upload de logo
      const logoFileRef = storageRef.child(logoFileName);
      await logoFileRef.put(logoFile);
      const logoFileURL = await logoFileRef.getDownloadURL();

      // Upload de capa
      const capaFileRef = storageRef.child(capaFileName);
      await capaFileRef.put(capaFile);
      const capaFileURL = await capaFileRef.getDownloadURL();

      // Criar a empresa usando a API
      const empresaData = {
        ...formData,
        conta: "empresa",
        userId: uuidv4(),
        logo: logoFileURL,
        capa: capaFileURL,
        quando: formattedDate,
        timestamp: new Date().toISOString(),
      };

      // Chamada à função createEmpresa que usa axios
      const resp_empresa = await createEmpresa(empresaData);
      console.log(resp_empresa);

      // Mostrar mensagem de sucesso usando o SweetAlert
      Swal.fire({
        icon: "success",
        title: "Cadastro bem-sucedido!",
        text: "Sua empresa foi cadastrada com sucesso!",
      });

      // Upload de arquivos para o Storage
      const anexosURLs = await Promise.all(
        (formData.anexos || []).map(async (anexo) => { // Usa um array vazio como fallback
          const storageRef = firebase.storage().ref();
          const fileRef = storageRef.child(`reclamacoes/${anexo.name}`); // Pasta "reclamacoes"
          await fileRef.put(anexo);
          return fileRef.getDownloadURL();
        })
      );


      setLoading(false);

      // Limpar os campos do formulário após o sucesso
      setFormData({
        emailEmpresa: "",
        numeroBI: "",
        siteEmpresa: "",
        whatsapp: "",
        enderecoEmpresa: "",
        sobre: "",
        nomeResponsavel: "",
        senha: "",
        categoria: "",
        anexos: [], // Limpar anexos também
      });
      setLogoFile(null);
      setCapaFile(null);
    } catch (error) {
      setLoading(false);
      console.error("Erro ao cadastrar empresa:", error);
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Este email já se encontra em uso, tente outro ou faça login!",
      });
    }
  };


  const handleCategoriaChange = (event) => {
    setFormData({
      ...formData,
      categoria: event.target.value,
    });
  };


  const options = [ 
    'Cursos',
    'Ensino Superior',
    'Ensino Médio',
    'Startup',
    'Supermercados',
    'Singular',
    'Bancos',
    'Divisas',
    'Transportes',
    'Telefonia, TV & Internet',
    'Beleza & Estetica',
    'Xtafeta',
    'Sites & Portais',
    'Loja Online',
    'Agência de Viagens',
    'Marketing / Agência',
    'Pagina',
    'Softwares',
    'IT e Tecnologia',
    'Saas',
    'E-commerce',
    'Alimentação',
    'Fast Food',
    'Outro'
  ];

  const handleCheckboxChange = (option) => {
    setFormData({
      ...formData,
      categoria: option // Define a categoria selecionada
    });
  };



  const handleTipoChange = (event) => {
    setFormData({
      ...formData,
      tipo: event.target.value // Atualiza o tipo (empresa ou singular)
    });
  };



  return (
    <>
      <Header
        style={{ marginBottom: "5rem" }}
        nomee={nomee}
        emaill={emaill}
        cart={cart}
      />
      <div className="c  mx-auto body pt-4 bg-light">
        <div className="container ">
          <div className="row ">
            <div className="col-12  text-center "></div>
            <div className="col-12  ">
              <div className="text-center">
                <br />
                <h2>
                  <b>Cadastre sua empresa no Reputação 360</b>
                </h2>
                <p className="fw-light fw-400 fw-thin f-16">
                  Obtenha ou gere leads e comunique se com seus consumidores no
                  melhor lugar.
                </p>
                <br />
              </div>
              <div className="container my-auto form-c form">
                <center>
                  <>
                    <div className="text-dark p-3">
                      <div className="text-start mb-4 headc">
                        <p>
                          <b className="text-danger">*</b>{" "}
                          <span className="text-secondary f-14">
                            Todos os campos são obrigatórios
                          </span>
                        </p>
                      </div>

                      <div className="col-12 my-2 col-md-12">

                        <div className="mt-4">
                          <label htmlFor="" className="text-secondary f-12">
                            Antes de tudo, diga nos, é uma empresa ou singular
                            <b className="text-danger">*</b>
                          </label>
                          <div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="tipo"
                                id="empresa"
                                value="empresa"
                                checked={formData.tipo === 'empresa'}
                                onChange={handleTipoChange}
                              />
                              <label className="form-check-label" htmlFor="empresa">
                                Empresa
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="tipo"
                                id="singular"
                                value="singular"
                                checked={formData.tipo === 'singular'}
                                onChange={handleTipoChange}
                              />
                              <label className="form-check-label" htmlFor="singular">
                                Singular
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row text-start">
                        <div className="col-12 my-2 col-lg-6">
                          <label htmlFor="" className="text-secondary f-12">
                            Logo da empresa ou sua foto de perfil <b className="text-danger">*</b>
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control rounded-1"
                            onChange={handleLogoChange}
                          />
                        </div>

                        <div className="col-12 my-2 col-lg-6">
                          <label htmlFor="" className="text-secondary f-12">
                            Foto de capa <b className="text-danger">*</b>
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control rounded-1"
                            onChange={handleCapaChange}
                          />
                        </div>

                        <div className="col-12 col-lg-6 my-2">
                          <label htmlFor="" className="text-secondary f-12">
                            Nome (Empresa / Pessoa) <b className="text-danger">*</b>
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control rounded-1"
                            placeholder="Digite seu nome e sobrenome"
                            name="nomeEmpresa"
                            value={formData.nomeEmpresa}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                nomeEmpresa: e.target.value,
                              })
                            }
                          />
                        </div>


                        <div className="col-12 d-none col-lg-6 my-2">
                          <label htmlFor="" className="text-secondary f-12">
                            <b className="text-danger">*</b> Nº BI/ NIF/
                            Passport
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control rounded-1"
                            placeholder="###########"
                            name="numeroBI"
                            value={formData.numeroBI}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                numeroBI: e.target.value,
                              })
                            }
                          />
                        </div>
                        <br />
                        <div className="col-12 my-2 col-lg-6">
                          <label htmlFor="" className="text-secondary f-12">
                            Website <span className="text-secondary ms-2"> (Opcional) </span>
                          </label>
                          <input
                            required
                            type="link"
                            className="form-control rounded-1"
                            placeholder="reputacao360.online"
                            name="siteEmpresa"
                            value={formData.siteEmpresa}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                siteEmpresa: e.target.value,
                              })
                            }
                          />
                        </div>
                        <br />
                        <div className="col-12 my-2 col-lg-12">
                          <label htmlFor="" className="text-secondary f-12">
                            Conte mais sobre você ou sua emprea <b className="text-danger">*</b>
                          </label>
                          <textarea
                            required
                            className="form-control rounded-1"
                            placeholder="Forneça uma breve descrição sobre você ou sua empresa"
                            name="sobre"
                            value={formData.sobre}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                sobre: e.target.value,
                              })
                            }
                          ></textarea>
                        </div>
                        <br />

                        <div className="col-12 hidd my-2 col-lg-6">
                          <label htmlFor="" className="text-secondary f-12">
                            Seu e-mail <b className="text-danger">*</b>
                          </label>
                          <input
                            required
                            type="email"
                            className="form-control rounded-1"
                            placeholder="Digite seu melhor email"
                            name="emailEmpresa"
                            value={formData.emailEmpresa}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                emailEmpresa: e.target.value,
                              })
                            }
                          />
                        </div>

                        <br />
                        <div className="col-12 my-2 col-lg-6">
                          <label htmlFor="" className="text-secondary f-12">
                            Crie uma senha <b className="text-danger">*</b>
                          </label>
                          <input
                            required
                            type="password"
                            className="form-control rounded-1"
                            placeholder="Crie uma senha (min 8 caracteres)"
                            minLength={8}
                            name="senha"
                            value={formData.senha}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                senha: e.target.value,
                              })
                            }
                          />
                        </div>
                        <br />
                        <div className="col-12 my-2 col-md-6">
                          <label htmlFor="" className="text-secondary f-12">
                            Endereço <b className="text-danger">*</b>
                          </label>
                          <input
                            required
                            type="tel"
                            className="form-control rounded-1"
                            placeholder="Digite o endereço atual "
                            name="enderecoEmpresa"
                            value={formData.enderecoEmpresa}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                enderecoEmpresa: e.target.value,
                              })
                            }
                          />
                        </div>
                        <br />

                        <br />
                        <div className="col-12 my-2 col-md-6">
                          <label htmlFor="" className="text-secondary f-12">
                            Telefone (Whatsapp preferêncial){" "}
                            <b className="text-danger">*</b>
                          </label>
                          <input
                            required
                            type="email"
                            className="form-control rounded-1"
                            placeholder="Digite seu whatsapp ou da empresa"
                            name="whatsapp"
                            value={formData.whatsapp}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                whatsapp: e.target.value,
                              })
                            }
                          />
                        </div>
                        <br />
                        <br />
                        <div className="col-12 my-2 col-md-12">
                          <label htmlFor="" className="text-secondary f-12">
                            Categoria da empresa / Negócio
                            <b className="text-danger">*</b>
                          </label>
                          <div className="d-flex flex-wrap">
                            {options.map((option) => (
                              <button
                                key={option}
                                type="button"
                                style={{ fontSize: '13px', padding: '2px 10px', borderRadius: '80px' }}
                                className={`btn rounded-full ${formData.categoria === option ? 'btn-primary' : 'btn-outline-primary'} m-1`}
                                onClick={() => handleCheckboxChange(option)}
                              >
                                {option}
                              </button>
                            ))}
                          </div>
                        </div>
                        <br />

                        <br />
                        <div className="titul hidden mt-3">
                          <div className="d-flex gap-2">
                            <i className="bi text-success bi-shield-lock-fill"></i>
                            <b>Outros Dados</b>
                          </div>
                        </div>

                        <br />
                        <div className="col-12 hidden my-2 col-lg-6">
                          <label htmlFor="" className="text-secondary f-12">
                            Nome do responsável <b className="text-danger">*</b>
                          </label>
                          <input
                            required
                            type="email"
                            className="form-control rounded-1"
                            placeholder="Digite o nome completo"
                            name="nomeResponsavel"
                            value={formData.nomeResponsavel}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                nomeResponsavel: e.target.value,
                              })
                            }
                          />
                        </div>

                      </div>
                    </div>
                    <br />
                    <button
                      disabled={load}
                      onClick={handleCadastroEmpresa}
                      className="d-flex mb-4 text-white px-5 btn btn-primary justify-content-center rounded-1"
                    >
                      {load ? (
                        <span>Cadastrando...</span>
                      ) : (
                        <span>Cadastrar</span>
                      )}
                    </button>
                  </>
                </center>
              </div>
              <br />
              <br />

              <br />
              <div className="text-center">
                <span>
                  Já possui uma conta ? <a href="/login">faça login</a>{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </>
  );
};

export default CadastroEmpresa;
