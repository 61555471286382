import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import obterDadosDoFirebase from "../../model/empresas2";
import EmpresaLoader from "../../components/empLoader";
import { RenderEmpresa } from "../../components/banner/render-empresa";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { BannerCategorias } from "../../components/banner/banner-categoria";

const EmpresasPorCategoria = ({ cart, nomee, emaill }) => {
  const { categoria } = useParams();
  const [empresasPorCategoria, setEmpresasPorCategoria] = useState([]);
  const [loading, setLoading] = useState(true);
  const [estrelasFiltro, setEstrelasFiltro] = useState(null);
  const [localidadeFiltro, setLocalidadeFiltro] = useState("");

  const filtrarEmpresas = (empresas) => {
    return empresas.filter((empresa) => {
      const atendeEstrelas = estrelasFiltro ? empresa.estrelas >= estrelasFiltro : true;
      const atendeLocalidade = localidadeFiltro ? empresa.localidade.toLowerCase().includes(localidadeFiltro.toLowerCase()) : true;
      return atendeEstrelas && atendeLocalidade;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dadosEmpresas = await obterDadosDoFirebase();
        const empresasFiltradas = dadosEmpresas.filter(
          (empresa) => empresa.categoria && typeof empresa.categoria === 'string' &&
            empresa.categoria.toLowerCase() === categoria.toLowerCase()
        );

        const empresasFiltradasComFiltros = filtrarEmpresas(empresasFiltradas);

        setEmpresasPorCategoria(empresasFiltradasComFiltros);
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar empresas por categoria:", error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [categoria, estrelasFiltro, localidadeFiltro]);

  return (
    <>
      <Header
        style={{ marginBottom: "5rem" }}
        nomee={nomee}
        emaill={emaill}
        cart={cart}
      />

      <div className="bg-secondary2 py-6">
        <div className="container">
          <br />
          <br />
          <center>
            <h1 className="font-bold">
              <b> Os melhores em <span className="underline">{categoria}</span></b>
            </h1>
            <span className="text-secondary">
              Compare as melhores empresas nesta categoria
            </span>
          </center>
          <br />
          <br />
        </div>
      </div>
      <br />
      <br />
      <center className="d-none">
        <h2 className="underline"><b>Outras Categorias</b></h2>
        <br />
      </center>
      {/*   <BannerCategorias /> */}

      <br />
      <div className="container gap-5 d-flex">
        <div className="filtros sm-hide col-12  col-sm-3">
          <div>
            <label>Filtrar por Estrelas:</label> <br />
            <select onChange={(e) => setEstrelasFiltro(Number(e.target.value))} defaultValue="">
              <option value="">Selecione</option>
              <option value="1">1 Estrela</option>
              <option value="2">2 Estrelas</option>
              <option value="3">3 Estrelas</option>
              <option value="4">4 Estrelas</option>
              <option value="5">5 Estrelas</option>
            </select>
          </div>
          <div>
            <label>Filtrar por Localidade:</label>
            <input
              type="text"
              placeholder="Digite a localidade"
              value={localidadeFiltro}
              onChange={(e) => setLocalidadeFiltro(e.target.value)}
            />
          </div>
        </div>

        <div className=" col-12 col-sm-8">

          {loading ? (
            <>
              <EmpresaLoader className="w" />
              <br />
              <EmpresaLoader className="w" />
              <br />
              <EmpresaLoader className="w" />
              <br />
              <br />
            </>
          ) : empresasPorCategoria.length > 0 ? (
            <div className="lista-empresas row">
              {
                empresasPorCategoria.slice(0, 5).map((empresa, index) => (
                  <div key={index} className="col-12 col-sm-6">
                    <RenderEmpresa empresa={empresa} index={index} />
                  </div>
                ))}
            </div>
          ) : (
            <div className="f">
              <p className="my-6 text-center">Nenhuma empresa encontrada nesta categoria.</p>
            </div>
          )}
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
};

export default EmpresasPorCategoria;
