import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { UserContext } from "../userContext";

import { NavLink } from "react-router-dom";
import "../../css/login.css";
import Header from "../../components/header";
import ScrollToTopLink from "../../components/scrollTopLink";
import Swal from "sweetalert2";
import { db } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/loader";
import { getClients } from "../../services/client-service";
import { loginEmpresa } from "../../services/eempresas";
import { useDispatch } from "react-redux";
import { loginFailure, loginStart, loginSuccess, loginSuccessEmpresa } from "../../store/userSlice";

const LoginEmpresa = ({ setNomee, setEmaill, cart, nomee, emaill }) => {
  const { handleLogin, push } = useContext(UserContext);

  document.title = `Entrar para sua conta | Reputação 360`;

  const [user, setUser] = useState(null);

  const dispatch = useDispatch();

  // verificar login do usuario

  useEffect(() => {
    // verificar login do usuario
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          // Substituindo a consulta ao Firestore pela chamada à API
          const querySnapshot = await getClients(); // Obtendo todos os clientes

          // Encontrar o usuário correspondente ao e-mail
          const userData = querySnapshot.find(client => client.email === user.email);

          if (userData) {
            // Se houver um documento correspondente, obter os dados
            const userDataResponse = {
              name: user.displayName ? user.displayName : userData.name,
              email: user.email,
              pictureUrl: user.photoURL,
              uid: user.uid,
              tel: user.phoneNumber ? user.phoneNumber : userData.phone,
              bi: userData.bi,
              city: userData.city,
              // Adicione outros campos conforme necessário
            };

            // Atualizar o estado do usuário com os dados
            setUser(userDataResponse);

            // Salvar dados no localStorage
            localStorage.setItem('users', JSON.stringify(userDataResponse));
          } else {
            console.warn("Documento não encontrado na API para o e-mail do usuário.");
          }
        } catch (error) {
          console.error("Erro ao buscar dados da API:", error);
        }
      } else {
        // Se o usuário não estiver logado, defina o estado do usuário como null
        setUser(null);
      }
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);

        setEmaill("");
        setNomee("");
        const userData = {
          name: "",
          email: "",
          pictureUrl: "",
          tel: "",
        };

        localStorage.setItem("users", JSON.stringify(userData));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [email, setEmail] = useState("");
  const [load, setLoad] = useState(false);
  const [password, setPassword] = useState("");

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLoginWithEmailAndPassword = async () => {
    setLoad(true);
    dispatch(loginStart());

    try {
      const response = await loginEmpresa(email, password); // Chama a API da empresa
      localStorage.setItem('token', response.token);
      //console.log(response)
      localStorage.setItem("empresa", JSON.stringify(response)); // Armazena os dados da empresa

      dispatch(loginSuccessEmpresa(response)); // Atualiza o estado com os dados da empresa
      window.location.href = '/perfil/minha-empresa';
    } catch (error) {
      // Tratamento de erro conforme necessário
      setLoad(false);
      dispatch(loginFailure());
      // ...
    } finally {
      setLoad(false);
    }
  };



  return (
    <>
      <ToastContainer />
      <Header
        style={{ marginBottom: "5rem" }}
        nomee={nomee}
        emaill={emaill}
        cart={cart}
      />
      <div className="c mx-auto pb-5 body">
        <div className="row ">
          <div className="col-12  text-center pt-sm-0 pt-lg-0"></div>
          <div className="col-12  pt-sm-5 ">
            <div className="text-center">
              <h4 className="f-reg">Acesse sua empresa</h4>
              <p className="fw-light fw-400 fw-thin f-14">
                Responda consumidores e utilize os dados a seu favor e melhore a
                experiência do seu cliente
              </p>
            </div>
            <div className="container pb-4 my-auto form">
              <center>
                {user ? (
                  <div>
                    <p className="text-primary">
                      Você está logado como <b></b> <br />
                      <span className="text-secondary">
                        Email: {user.email}
                      </span>
                    </p>

                    <button className="btn btn-danger" onClick={handleLogout}>
                      Sair
                    </button>
                  </div>
                ) : (
                  <>
                    <div>
                      <div className=" text-start">
                        <div className="col-12">
                          <label htmlFor="" className="text-secondary f-12">
                            Email ou Telefone
                          </label>
                          <input
                            type="email"
                            className="form-control rounded-1"
                            placeholder="Digite o email"
                            value={email}
                            onChange={handleChangeEmail}
                          />
                        </div>
                        <br />
                        <div className="col-12">
                          <label htmlFor="" className="text-secondary f-12">
                            Palavra passe
                          </label>
                          <input
                            type="password"
                            className="form-control rounded-1"
                            placeholder="Digite sua palavra passe"
                            value={password}
                            onChange={handleChangePassword}
                          />
                        </div>
                        <br />
                      </div>
                    </div>

                    {/* Botão de login */}
                    <button
                      disabled={load}
                      className="d-flex w-100 rounded-1 justify-content-center btn btn-primary"
                      onClick={handleLoginWithEmailAndPassword}
                    >
                      {load ? <Loader /> : <span>Entrar</span>}
                    </button>
                    <br />
                    <a href="/login">Entrar como cliente</a>
                  </>
                )}
              </center>
            </div>
            <br />
            <div className="container my-auto form">
              <center>
                <>
                  <div className="pb-2">
                    <b className="text-dark">Não tem uma conta ?</b>
                  </div>
                  <ScrollToTopLink
                    to={"/cadastro"}
                    className="d-flex  w-100 btn-google btn btn-outline-primary"
                  >
                    <span>Faça seu cadastro</span>
                  </ScrollToTopLink>
                </>
              </center>
            </div>
            <br />
            {/* <div className="text-center">
              <span>
                Não tem uma conta ? <a href="/cadastro">Cadastre-se</a>{" "}
              </span>
            </div> */}
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default LoginEmpresa;
