import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: 'user',
  initialState: {
    empresa: null,
    client: null,
    loading: false,
  },
  reducers: {
    loginStart(state) {
      state.loading = true;
    },
    loginSuccessEmpresa(state, action) {
      state.empresa = action.payload; // Salva os dados da empresa
      state.loading = false;
    },
    loginSuccessClient(state, action) {
      state.client = action.payload; // Salva os dados do cliente
      state.loading = false;
    },
    loginFailure(state) {
      state.loading = false;
    },
    logoutEmpresa(state) {
      state.empresa = null; // Limpa os dados da empresa
    },
    logoutClient(state) {
      state.client = null; // Limpa os dados do cliente
    },
  },
});

export const {
  loginStart,
  loginSuccessEmpresa,
  loginSuccessClient,
  loginFailure,
  logoutEmpresa,
  logoutClient
} = userSlice.actions;

export default userSlice.reducer;
