
import africa from "../../imgs/africa.png";

export function CardsDescsR360() {
  return (
    <div className="pt-4 pb-5 benefits bg-success3">
      <div className="bbann justify-content-between d-flex">
        <img src={africa} alt="" className="africa-ban" />
        <img src={africa} alt="" className="africa-ban" />
      </div>
      <div className="container">
        <div className="row ">
          <div className="col-12 col-lg-3 col-xxl-4">
            <div className="container titt">
              <h2 className="text-white">
                <b>Reputação 360 para empresas</b>
              </h2>
              <h5
                className="text-white "
              >
                O Reputação 360 é o canal OFICIAL do consumidor Angolano! Ele
                atua como um canal independente de comunicação entre
                consumidores e empresas.
              </h5>
              <img src={africa} alt="" className="africa-ban" />
            </div>
          </div>
          <div className="col-12 col-lg-9 col-xxl-8">
            <div className="cards-emp w-100">
              <div className="row">
                <div className="my-3 col-12 col-sm-6 col-md-4">
                  <div className="p-3 bg-white shadow-md card-benefit rounded-1">
                    <b className="f-reg">Área da Empresa</b>
                    <br />
                    <span className="f-14">
                      Responda as reclamações, peça avaliação e personalize
                      sua página com informações básicas.
                    </span>
                    <br />
                    <br />
                    <br />
                    <a href="/login" className="f-14">
                      Acessar <i className="bi bi-arrow-right-short"></i>
                    </a>
                  </div>
                </div>
                <div className="my-3 col-12 col-sm-6 col-md-4">
                  <div className="p-3 bg-white shadow-md card-benefit rounded-1">
                    <b className="f-reg">Cadastrar Empresa</b>
                    <br />
                    <span className="f-14">
                      Conquiste a confiança de milhões de pessoas em todo o
                      Brasil e esteja no maior site de pesquisa de compra.
                    </span>
                    <br />
                    <br />
                    <br />
                    <a href="/cadastro/empresa" className="f-14">
                      Faça parte do R360{" "}
                      <i className="bi bi-arrow-right-short"></i>
                    </a>
                  </div>
                </div>
                <div className="my-3 col-12 col-sm-6 col-md-4">
                  <div className="p-3 bg-white shadow-md card-benefit rounded-1">
                    <b className="f-reg">Blog R360</b>
                    <br />
                    <span className="f-14">
                      O melhor conteúdo para você entender mais sobre o R360,
                      atendimento e direito do consumidor.
                    </span>
                    <br />
                    <br />
                    <br />
                    <a href="/#blog" className="f-14">
                      Ver matérias <i className="bi bi-arrow-right-short"></i>
                    </a>
                  </div>
                </div>
                <div className="my-3 col-12 col-sm-6 col-md-4">
                  <div className="p-3 bg-white shadow-md card-benefit rounded-1">
                    <b className="f-reg">Divulgue sua marca</b>
                    <br />
                    <span className="f-14">
                      Aproveite para aumentar a confiança dos consumidores que
                      pesquisam sobre você e destaque sua empresa.
                    </span>
                    <br />
                    <br />
                    <br />
                    <a href="/cadastro/empresa" className="f-14">
                      Saiba mais <i className="bi bi-arrow-right-short"></i>
                    </a>
                  </div>
                </div>
                <div className="my-3 col-12 col-sm-6 col-md-4">
                  <div className="p-3 bg-white shadow-md card-benefit rounded-1">
                    <b className="f-reg">Gerencie suas reclamações</b>
                    <br />
                    <span className="f-14">
                      Agilize seu atendimento e facilite a rotina de quem
                      atende o Reputação 360
                    </span>
                    <br />
                    <br />
                    <br />
                    <a href="/cadastro/empresa" className="f-14">
                      Acesse agora <i className="bi bi-arrow-right-short"></i>
                    </a>
                  </div>
                </div>
                <div className="my-3 col-12 col-sm-6 col-md-4">
                  <div className="p-3 bg-white shadow-md card-benefit rounded-1">
                    <b className="f-reg">Aumente a performance do seu time</b>
                    <br />
                    <span className="f-14">
                      Atualize sua equipe com conhecimentos e insights para
                      enfrentar desafios do relacionamento com o consumidor.
                    </span>
                    <br />
                    <br />
                    <br />
                    <a href="/cadastro/empresa" className="f-14">
                      Acesse agora <i className="bi bi-arrow-right-short"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}