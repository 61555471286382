
import Cookies from "js-cookie";
import { apiToken, AxiosHttpClient } from "../api/axiosHttpClient";
import axios from "axios";

const httpClient = new AxiosHttpClient()

// Função para obter todas as empresas
export const getEmpresas = async () => {
  // Tenta obter as empresas armazenadas nos cookies
  //const empresasArmazenadas = Cookies.get("empresas");

  // Se as empresas esão disponíveis nos cookies, retorne-as
  //if (empresasArmazenadas) {
  // console.log(empresasArmazenadas)
  // return JSON.parse(empresasArmazenadas);
  //}

  // Se não, faça a requisição à API
  try {
    const response = await httpClient.get(`/empresas`);
    // Armazena os dados no cookie por 7 dias
    //Cookies.set("empresas", JSON.stringify(response), { expires: 1 });
    // console.log(response)
    return response;
  } catch (error) {
    console.error("Erro ao buscar empresas:", error);
    throw error;
  }
};

// Função para obter uma empresa por ID
export const getEmpresaBySlug = async (slug) => {
  // Tenta obter a empresa armazenada nos cookies
  //const empresaArmazenada = Cookies.get(`empresa_${id}`);

  // Se a empresa está disponível nos cookies, retorne-a
  //if (empresaArmazenada) {
  //console.log(empresaArmazenada)
  //return JSON.parse(empresaArmazenada);
  //}

  // Se não, faça a requisição à API
  try {
    const response = await httpClient.get(`/empresas/slug/${slug}`);
    // Armazena os dados no cookie por 7 dias
    //Cookies.set(`empresa_${id}`, JSON.stringify(response), { expires: 7 });
    return response; // Retorna os dados da API
  } catch (error) {
    console.error("Erro ao obter empresa:", error);
    throw error;
  }
};

// Função para obter uma empresa por ID
export const getEmpresaById = async (id) => {
  // Tenta obter a empresa armazenada nos cookies
  //const empresaArmazenada = Cookies.get(`empresa_${id}`);

  // Se a empresa está disponível nos cookies, retorne-a
  //if (empresaArmazenada) {
  //console.log(empresaArmazenada)
  //return JSON.parse(empresaArmazenada);
  //}

  // Se não, faça a requisição à API
  try {
    const response = await httpClient.get(`/empresas/${id}`);
    // Armazena os dados no cookie por 7 dias
    //Cookies.set(`empresa_${id}`, JSON.stringify(response), { expires: 7 });
    return response; // Retorna os dados da API
  } catch (error) {
    console.error("Erro ao obter empresa:", error);
    throw error;
  }
};


// Função para criar uma nova empresa
export const createEmpresa = async (empresaData) => {
  try {
    const response = await httpClient.post(`/empresas`, empresaData);
    return response; // Retorne o ID da empresa criada se necessário
  } catch (error) {
    console.error("Erro ao criar empresa:", error);
    throw error;
  }
};

// Função para atualizar uma empresa
export const updateEmpresa = async (id, updatedData) => {
  try {
    const response = await httpClient.put(`/empresas/${id}`, updatedData);
    return response;
  } catch (error) {
    console.error("Erro ao atualizar empresa:", error);
    throw error;
  }
};

// Função para excluir uma empresa
export const deleteEmpresa = async (id) => {
  try {
    const response = await httpClient.delete(`/empresas/${id}`);
    return response; // Retorne a mensagem de sucesso se necessário
  } catch (error) {
    console.error("Erro ao excluir empresa:", error);
    throw error;
  }
};


// Função para fazer login empresa
export const loginEmpresa = async (email, password) => {

  const data = {
    email: email,
    password: password
  }

  const config = {
    headers: {
      Authorization: `Bearer ${apiToken}`
    }
  };

  try {
    const response = await axios.post(`https://rapi-360.onrender.com/api/login`, data, config);

    // Armazena o token JWT no localStorage
    localStorage.setItem('token', response.data.token);

    // Retorna os dados do cliente
    return response.data.client;
  } catch (error) {
    console.error('Erro ao fazer login:', error);
    throw error.response ? error.response.data.message : 'Erro de conexão';
  }
};
