import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { faUniversity, faShoppingCart, faGraduationCap, faLaptopCode, faStore, faRocket, faShoppingBag, faCircle, faCodeMerge, faQuestionCircle, faCar, faMotorcycle, faBullhorn, faPlane, faBurger, faBowlFood, faBowlRice, faBank, faCoins, faSchool, faBook, faBrain } from "@fortawesome/free-solid-svg-icons";
import obterDadosDoFirebase from "../../model/empresas2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie"; // Importa a biblioteca de cookies



export const obterCategoriasUnicas = async () => {
  try {
    const empresas = await obterDadosDoFirebase();
    const todasCategorias = empresas.map((empresa) => empresa.categoria);
    const categoriasUnicas = [...new Set(todasCategorias)].filter(
      (categoria) => categoria && typeof categoria === "string"
    );
    //console.log(categoriasUnicas)
    return categoriasUnicas;
  } catch (error) {
    console.error("Erro ao obter categorias:", error.message);
    return [];
  }
};


export const BannerCategorias = () => {
  const [categorias, setCategorias] = useState([]);
  const [loading, setLoading] = useState(true); // Estado de loading

  // Mapeia as categorias para seus respectivos ícones
  const categoriaIcones = {
    Bancos: faUniversity,
    Supermercados: faShoppingCart,
    Softwares: faCodeMerge,
    "Loja Online": faStore,
    Outro: faQuestionCircle,
    Xtafeta: faMotorcycle,
    "IT e Tecnologia": faLaptopCode,
    Startup: faRocket,
    Transportes: faCar,
    Divisas: faCoins,
    "E-commerce": faShoppingBag,
    Marketing: faBullhorn,
    "Agência de Viagens": faPlane,
    'Alimentação': faBowlRice,
    'Fast Food': faBurger,
    'Cursos': faBrain,
    'Ensino Superior': faGraduationCap,
    'Ensino Médio': faSchool,
  };


  useEffect(() => {
    const fetchCategorias = async () => {
      // Verifica se já existem categorias armazenadas no localStorage
      const categoriasArmazenadas = localStorage.getItem("categorias");
      if (categoriasArmazenadas) {
        // Se existir no localStorage, exibe imediatamente
        setCategorias(JSON.parse(categoriasArmazenadas));
        setLoading(false); // Define loading como false para evitar a espera
      } else {
        setLoading(true); // Caso não tenha nada armazenado, mostra o loading
      }

      // Faz a requisição em segundo plano para obter categorias atualizadas
      try {
        const categoriasUnicas = await obterCategoriasUnicas();
        setCategorias(categoriasUnicas); // Atualiza o estado com as novas categorias
        localStorage.setItem("categorias", JSON.stringify(categoriasUnicas)); // Atualiza o localStorage
        setLoading(false); // Garante que o loading seja false após a atualização
      } catch (error) {
        console.error("Erro ao buscar categorias:", error.message);
        setLoading(false); // Define loading como false em caso de erro
      }
    };

    fetchCategorias();
  }, []);


  // Memoriza as categorias para evitar reprocessamento
  const memorizedCategorias = useMemo(() => {
    return categorias.map((categoria, index) => (
      <div className="col-6 col-sm-4 col-md-3 col-lg-2  text-center mb-4" key={index}>
        <a
          href={`/empresas/categoria/${categoria.toLowerCase()}`}
          className="categoria-item d-flex flex-column align-items-center justify-content-center text-decoration-none p-2"
        >
          <FontAwesomeIcon
            icon={categoriaIcones[categoria] || faCircle} // Usa o ícone padrão se não encontrar a categoria
            size="lg"
            className="mb-2"
          />
          <span className="categoria-text">{categoria}</span>
        </a>
      </div>
    ));
  }, [categorias, categoriaIcones]); // Dependências do useMemo

  if (loading) {
    // Placeholder de skeleton
    return (
      <div className="categorias-banner container">
        <div className="row justify-content-center">
          {Array.from({ length: 11 }).map((_, index) => ( // Cria 8 placeholders
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 text-center mb-4" key={index}>
              <div className="categoria-item d-flex flex-column align-items-center justify-content-center p-3 skeleton">
                <div className="skeleton-icon mb-2" />
                <div className="skeleton-text" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="categorias-banner container">
      <div className="row justify-content-center">
        {memorizedCategorias}
      </div>
    </div>
  );
};
