export function SejaOuvido() {
  return (
    <div className="text-center bg-success3">
      <br />
      <br />
      <div className="container py-2 text-white w-75">
        <h1 className="f-reg fw-bold">Seja Ouvido</h1>
        <h3>
          R360 é umaplataforma de avaliação aberta para todos. compartilhe
          suas experiências para ajudar outras pessoas a fazerem escolhas
          melhores e incentivar as empresas a melhorarem seu jogo, atendimento
          ou serviço
        </h3>
        <br />
        <button className="bg-white btn text-dark rounded-pill">
          Avaliar empresas
        </button>
      </div>
      <br />
      <br />
    </div>
  )
}