import React from "react";
import { RenderEmpresa } from "../banner/render-empresa";

export const CategorySection = ({ empresasPorCategoria }) => (
  <div className="banner-container">
    {empresasPorCategoria.map(({ categoria, empresas }) => (
      <div key={categoria} style={{ alignContent: "flex-start", marginTop: "3rem" }} className="container">
        <h2 style={{ fontSize: "24px" }} className="banner-title text-start">Os Melhores em <a href={"/empresas/categoria/" + categoria} className="font-bold">{categoria}</a></h2>
        <div className="banner-grid">
          {empresas.map((empresa, index) => (
            <RenderEmpresa key={index} empresa={empresa} />
          ))}
        </div>
      </div>
    ))}
    <br />
    <br />
  </div>
);
