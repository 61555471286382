import { getEmpresas } from "../services/eempresas";
import { obterReclamacoes } from "../services/reclamacoes";

const obterDadosDoFirebase = async () => {
  try {
    // Obtém todas as empresas da API
    const empresas = await getEmpresas();

    // Obtém todas as reclamações da API
    const reclamacoes = await obterReclamacoes();


    const dadosEmpresas = empresas.map(empresa => {
      // Converter o ID da empresa para string para garantir que o tipo seja o mesmo
      const empresaIdString = String(empresa.id);

      // Filtra as reclamações relacionadas à empresa atual
      const reclamacoesData = reclamacoes.filter(reclamacao => String(reclamacao.empresaId) === empresaIdString);

      const totalClassificacoes = reclamacoesData.reduce(
        (total, reclamacao) => total + reclamacao.classificacao,
        0
      );

      const mediaClassificacoes =
        reclamacoesData.length > 0
          ? totalClassificacoes / reclamacoesData.length
          : 0;

      return {
        id: empresa.id,
        avaliacao: mediaClassificacoes.toFixed(1),
        ...empresa,
      };
    });

    return dadosEmpresas;
  } catch (error) {
    console.error('Erro ao obter dados:', error.message);
    return []; // ou retorne um valor padrão caso ocorra um erro
  }
};

export default obterDadosDoFirebase;
