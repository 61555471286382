import ScrollToTopLink from "../scrollTopLink";
import eduka from "../../imgs/eduka.png";

export function RedukaContainer() {
  return (

    <div className="container desconto">
      <div className="flex-row-reverse row">
        <div className="col-12 imm col-md-5">
          <img src={eduka} alt="" className="my-auto  -100" />
        </div>
        <div className="col-12 tt col-md-7">
          <div className="my-auto">
            <h2 className="fw-bold f-reg">R360 EDUKA</h2>

            <span>
              Aqui, você e sua equipe adquirem conhecimento e insights
              atualizados para colocar a mão na massa e enfrentar os desafios
              reais de um time de atendimento. Construa uma carreira de
              sucesso com o R360 Eduka!
            </span>

            <br />
            <br />
            <ScrollToTopLink
              disabled
              to={"/descontos"}
              className="btn rounded-1 disabled btn-outline-dark"
            >
              Brevemente Disponível
            </ScrollToTopLink>
          </div>
        </div>
      </div>
    </div>
  )
}