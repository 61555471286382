import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import "firebase/compat/firestore";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ReclamacaoIt from "../../components/reclamacaoComponent/reclamacaoItem2";
import { getComplaintsByCompanyId, responderReclamacao } from "../../services/reclamacoes";
import { useDispatch, useSelector } from "react-redux";
import { logoutClient, logoutEmpresa } from "../../store/userSlice";

function PerfilMinhaEmpresa({ nomee, emaill, cart }) {


  const userFromRedux = useSelector((state) => state.user.empresa);

  const [empresa, setEmpresa] = useState(userFromRedux);
  const [reclamacoesEmpresa, setReclamacoesEmpresa] = useState([]);
  const [resposta, setResposta] = useState("");

  const [isModalOpen, setModalOpen] = useState(false);
  const [reclamacaoAtual, setReclamacaoAtual] = useState(null);

  const [telefone, setTelefone] = useState(empresa.whatsapp); // Initialize telefone state
  const [outrosDados, setOutrosDados] = useState({
    cidade: empresa.enderecoEmpresa,
    site: empresa.siteEmpresa,
    sobre: empresa.sobre,
    fb: empresa.fb,
  }); // Initialize outrosDados state
  const [ph, setPh] = useState(false); // Initialize loading state

  useEffect(() => {
    // Load company data
    const userString = localStorage.getItem("empresa");
    //console.log(empresa)
    if (userString) {
      const empresa = JSON.parse(userString);
      //setEmpresa(empresa);
      carregarReclamacoes(empresa._id); // Load complaints
    }
  }, []);

  const carregarReclamacoes = async (empresaId) => {
    try {
      const reclamacoes = await getComplaintsByCompanyId(empresaId); // Ensure this function is defined
      setReclamacoesEmpresa(reclamacoes);
      //console.log(empresaId)
    } catch (error) {
      console.error("Erro ao carregar reclamações:", error);
    }
  };

  const enviarResposta = async (reclamacaoId) => {
    try {
      const updatedReclamacao = await responderReclamacao(reclamacaoId, resposta, empresa.nomeEmpresa); // Ensure this function is defined
      carregarReclamacoes(empresa._id); // Update complaints with response
    } catch (error) {
      console.error("Erro ao enviar resposta:", error);
    }
  };

  const atualizarInformacoes = async () => {
    setPh(true); // Set loading state 
    setPh(false); // Reset loading state
  };

  useEffect(() => {
    const pegarEmpresa = async () => {
      try {
        // Assuming complaints are in a "reclamacoes" collection
        const reclamacoes = getComplaintsByCompanyId(empresa._id)
        setReclamacoesEmpresa(reclamacoes);
      } catch (error) {
        console.error("Erro ao pegar empresa:", error.message);
      }
    };

    if (empresa.nome) { // Ensure empresa has been set before fetching complaints
      pegarEmpresa();
    }
  }, [empresa]);

  const [reclamacoesParaExibir, setReclamacoesParaExibir] = useState(4);

  const handleVerMais = () => {
    setReclamacoesParaExibir((prevCount) => prevCount + 4);
  };

  const handleVerMenos = () => {
    setReclamacoesParaExibir((prevCount) => Math.max(prevCount - 4, 4));
  };


  const dispatch = useDispatch()

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove o token do localStorage
    dispatch(logoutClient()); // Limpa os dados da cliente
    dispatch(logoutEmpresa()); // Limpa os dados da empresa
    window.location.href = "/login"
  };


  // Ação para logout de cliente
  const logoutClientAction = () => (dispatch) => {
    localStorage.removeItem('token'); // Remove o token do localStorage
    dispatch(logoutClient()); // Limpa os dados do cliente
    window.location.href = "/login"
  };

  //console.log(empresa)


  // Função para abrir o modal com a reclamação selecionada
  const handleAbrirModal = (reclamacao) => {
    setReclamacaoAtual(reclamacao);
    setModalOpen(true);
  };

  // Função para fechar o modal
  const handleFecharModal = () => {
    setModalOpen(false);
    setReclamacaoAtual(null);
    setResposta(''); // Limpa o campo de resposta ao fechar
  };

  // Função para enviar a resposta
  const handleSalvarResposta = async () => {
    try {
      const owner = "Nome do autor"; // Substitua pelo valor correto
      await responderReclamacao(reclamacaoAtual._id, resposta, empresa.nomeEmpresa);
      alert('Resposta enviada com sucesso!');
      handleFecharModal(); // Fecha o modal após salvar
    } catch (error) {
      console.error('Erro ao salvar resposta:', error);
    }
  };

  return (
    <>
      <Header nomee={nomee} emaill={emaill} cart={cart} />

      <div className="text-center c">
        <div style={{ height: "200px", background: `url('${empresa.capa} center center')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>

        </div>
        <img className="user-p " style={{ marginTop: "-1.9rem" }} src={empresa.logo ?? empresa.logo} alt="" />
        <h2> <a href={"/empresa/" + empresa._id}>{empresa.nomeEmpresa}</a></h2>
        <div className="d-flex gap-2 flex-wrap justify-content-center">

          <span className="f-12 text-secondary">
            ID:{" "}
            <b>
              <a href={"/empresa/" + empresa._id}>{empresa._id}</a>
            </b>
          </span>
          <span className="my-auto">
            &middot;
          </span>
          <span className="f-12 text-secondary">


            <span>{empresa.quando}</span>
          </span>
        </div>
        <p className="f-12">{empresa.emailEmpresa}</p>
        <div className="d-flex justify-content-center gap-2 flex-wrap">
          <p className={`f-12 ${empresa.selo ? "text-success" : "text-danger"}`}>{empresa.selo ? "Verificado" : "Sem Selo R360"}</p>
          <p className="f-12">{empresa.emailEmpresa}</p>
        </div>
        <NavLink
          onClick={handleLogout}
          className={"my-auto btn btn-outline-danger"}
        >
          Terminar sessão
        </NavLink>

        {/* Formulário para atualizar informações */}

        <div className="row container mx-auto">
          <div className="col-12 col-md-5">
            <div className="text-start">
              <br />
              <label htmlFor="" className="f-12 text-secondary">
                Telefone
              </label>
              <input
                value={telefone}
                type="tel"
                className=" mx-auto form-control"
                onChange={(e) => setTelefone(e.target.value)}
              />

              {/* Adicione outros campos conforme necessário */}
              <label htmlFor="" className="f-12 text-secondary">
                Cidade
              </label>
              <input
                value={outrosDados.cidade}
                type="text"
                className=" mx-auto form-control"
                onChange={(e) =>
                  setOutrosDados({ ...outrosDados, cidade: e.target.value })
                }
              />

              <label htmlFor="" className="f-12 text-secondary">
                Site
              </label>
              <input
                value={outrosDados.site}
                type="text"
                className=" mx-auto form-control"
                onChange={(e) =>
                  setOutrosDados({ ...outrosDados, site: e.target.value })
                }
              />

              <label htmlFor="" className="f-12 text-secondary">
                Sobre
              </label>
              <textarea
                value={outrosDados.sobre}
                className=" mx-auto form-control"
                onChange={(e) =>
                  setOutrosDados({ ...outrosDados, sobre: e.target.value })
                }
              />

              {/* Inputs para os campos adicionais */}
              <label htmlFor="" className="f-12 text-secondary">
                Facebook
              </label>
              <input
                value={outrosDados.fb}
                type="text"
                className=" mx-auto form-control"
                onChange={(e) =>
                  setOutrosDados({ ...outrosDados, fb: e.target.value })
                }
              />

              <label htmlFor="" className="f-12 text-secondary">
                Instagram
              </label>
              <input
                value={outrosDados.insta}
                type="text"
                className=" mx-auto form-control"
                onChange={(e) =>
                  setOutrosDados({ ...outrosDados, insta: e.target.value })
                }
              />

              <label htmlFor="" className="f-12 text-secondary">
                YouTube
              </label>
              <input
                value={outrosDados.youtube}
                type="text"
                className=" mx-auto form-control"
                onChange={(e) =>
                  setOutrosDados({ ...outrosDados, youtube: e.target.value })
                }
              />
            </div>
            <button
              disabled={ph}
              className="btn d-none btn-sm btn-success"
              onClick={atualizarInformacoes}
            >
              {ph ? "Atualizando..." : "Atualizar"}
            </button>

            {/* Restante do código para exibição de reclamações e outras informações */}

            <br />
            <br />
            <br />
          </div>

          <div className="col-12 col-md-7">
            <center className="">
              <br />
              <div className="container">
                <div className="card-sobre-empresa border-1 text-start  bg-white p-3">
                  <b className="text-dark f-reg">Reclamações de clientes </b>

                  {/* O que estão falando desta empresa, card */}

                  {empresa.emailEmpresa ? (
                    <>
                      {reclamacoesEmpresa?.length !== 0 ? (
                        reclamacoesEmpresa
                          .sort((a, b) => {
                            const dateA = new Date(a.quando);
                            const dateB = new Date(b.quando);
                            return dateA - dateB; // Classificar as datas pelo mais recente
                          })
                          .slice(0, reclamacoesParaExibir)
                          .map((reclamacao, index) => (
                            <div key={index}>
                              <ReclamacaoIt handleAbrirModal={handleAbrirModal} reclamacao={reclamacao} />
                            </div>
                          ))
                      ) : (
                        <center>
                          <br />
                          <br />
                          <i className="bi bi-megaphone f-24 text-secondary"></i>{" "}
                          <br />
                          <br />
                          <span className="text-secondary f-14 w-75">
                            Não há ainda reclamações ou avaliações feitas por sí
                          </span>
                        </center>
                      )}

                      {reclamacoesEmpresa.length > reclamacoesParaExibir && (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={handleVerMais}
                        >
                          Ver mais
                        </button>
                      )}
                      {reclamacoesParaExibir > 4 && (
                        <button
                          className="btn btn-secondary btn-sm"
                          onClick={handleVerMenos}
                        >
                          Ver menos
                        </button>
                      )}
                    </>
                  ) : (
                    <p>Carregando...</p>
                  )}

                </div>
              </div>
            </center>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h4>Responder Reclamação</h4>
            <p>
              <strong>Reclamação:</strong> {reclamacaoAtual?.historia}
            </p>
            <label>Sua Resposta:</label>
            <Editor
              apiKey="sh2tciuk9rcnssaqs1unp7o5lrjym07pwkwsizni3bcpmgn2" // Substitua com a sua chave da API TinyMCE
              value={resposta}
              init={{
                height: 200,
                menubar: false,
                plugins: [
                  'advlist autolink lists link charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help'
              }}
              onEditorChange={(content) => setResposta(content)}
            />

            <div className="modal-actions">
              <button onClick={handleSalvarResposta} className="btn btn-success">
                Salvar
              </button>
              <button onClick={handleFecharModal} className="btn btn-secondary">
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}




      <Footer />
    </>
  );
}

export default PerfilMinhaEmpresa;
