

import bb3 from "../../imgs/mmm.png";

export function HeaderBanner() {
  return (
    <div className="shadow-md banner dd-none bg-success text-start">
      <div style={{ paddingTop: '16vh' }} className="container body-banner  text-start w-lg-50">

        <h2 className="text-white f-reg bbn titulo">
          O <b className="text-">Reputação</b> <b className="text-">360</b> te
          aproxima das marcas que têm a confiança do consumidor!
          <br />
          Encontre empresas pra confiar
        </h2>
      </div>
      <div className="bbb">
        <div className="b1"></div>
        <div className="d1"></div>
        <img src={bb3} alt="" className="b3" />
      </div>
    </div>

  )
}