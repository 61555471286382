import React, { useEffect } from 'react';


const Loader = () => {
    return(
        <div className='load rounded-circle mx-auto'>

        </div>
);
};

export default Loader;
