import React from "react";
import { RenderEmpresa } from "../banner/render-empresa";

export const CategorySectionIndividual = ({ empresasPorCategoriaIndividual }) => {
  // console.log(empresasPorCategoriaIndividual)
  return (
    <div className="banner-container ">

      <div style={{ alignContent: "flex-start", marginTop: "3rem" }} className="container">
        <h2 style={{ fontSize: "30px" }} className="banner-title underlined text-start">Entidades Individuais Verificados Confiáveis </h2>
        <div className="banner-grid">
          {empresasPorCategoriaIndividual.map((empresa, index) => (
            <RenderEmpresa key={index} empresa={empresa} />
          ))}
        </div>
      </div>

    </div>
  )
};
