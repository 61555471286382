import axios from 'axios';


export const apiToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2ZjU4NWY4ZmI5MjA0MzU0ZjIzMjg4YSIsImVtYWlsIjoiR2VyYWxkb1YuTEBnLW1haWwuY29tIiwiaWF0IjoxNzI3NDM0MTQ4LCJleHAiOjE3NDI5ODYxNDh9.UKhT5NrBcxDK6q9U3RPDXSGioA4xl_3vq2xl5xk_d2Y";
//export const API_URL = "http://localhost:5099/api";
//export const API_URL = "https://rapi-360.onrender.com/api";
export const API_URL = "https://api.reputacao360.online/api";


export class AxiosHttpClient {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: API_URL,
    });
    this.setAuthorizationToken(apiToken);
  }

  setAuthorizationToken(token) {
    this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  async get(url, params) {
    try {
      const response = await this.axiosInstance.get(url, { params });
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw new Error('Failed to fetch data');
    }
  }

  async post(url, data) {
    try {
      const response = await this.axiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw new Error('Failed to post data');
    }
  }

  async put(url, data) {
    try {
      const response = await this.axiosInstance.put(url, data);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw new Error('Failed to update data');
    }
  }

  async delete(url) {
    try {
      const response = await this.axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      this.handleError(error);
      throw new Error('Failed to delete data');
    }
  }

  handleError(error) {
    if (axios.isAxiosError(error)) {
      const status = error.response?.status;
      const message = error.response?.data?.message || error.message;

      switch (status) {
        case 401:
          console.error('Unauthorized access:', message);
          break;
        case 422:
          console.error('Validation error:', message);
          break;
        case 500:
          console.error('Server error:', message);
          break;
        default:
          console.error('An error occurred:', message);
      }
    } else {
      console.error('Unexpected error:', error);
    }
  }
}
