import React from "react"; 
import nt1 from '../imgs/bgs/nteka1.jpg'
import nt2 from '../imgs/bgs/nteka2.jpg'
import nt3 from '../imgs/bgs/nteka3.jpg'

const Pub = () => {
  return (
    <>
      <div className="pubb">
        <div className="text-center">
          <b className="text-secondary f-20">Publicidade</b>

          <br />
          <span className="f-14 text-secondary">Clique no anuncio</span>
        </div>
        <a href="https://l.facebook.com/l.php?u=http%3A%2F%2Fwww.lojanteka.com%2F%3Ffbclid%3DIwZXh0bgNhZW0CMTAAAR2cTaDi8l8hP8n0kKBRhn7qOa43h3uYwBQRX4fnueV4xfP45BEi2rtEaYs_aem_EZntG8m5ytkPKLSaakfM_g&h=AT1b-C6ifB59TS31mxCzSS5DuwPZIxsmFPDkb1dKyBDl2UjuFW4oZ0cEQ19jkCOJGztZREpMJNNkaM7WOe5fdLN02oeLr3VCSqDA47F7j88yOQqMA1AXMoJgalvIYF06F18hnA" target="__blank">
          <div className="publicidad text-white my-3 ">
            <img src={nt1} alt="" />
            <img src={nt2} alt="" />
            <img src={nt3} alt="" />
          </div>
        </a>
      </div>
    </>
  );
};

export default Pub;
