import React, { useState, useEffect, useContext } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { UserContext } from "./userContext";
import { NavLink } from "react-router-dom";
import "../css/login.css";
import logo from "../imgs/icone.png";
import logo2 from "../imgs/logo-d.png";
import axios from "axios";
import Header from "../components/header";
import Footer from "../components/footer";
import ScrollToTopLink from "../components/scrollTopLink";
import { db } from "./firebase";
import Swal from "sweetalert2";
import { createClient } from "../services/client-service";

const CadastroConsumidor = ({ setNomee, setEmaill, cart, nomee, emaill }) => {
  const { handleLogin, push } = useContext(UserContext);
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    address: '',
    email: '',
    password: '',
  });
  document.title = `Cadastro de consumidor | Reputação 360`;
  const [user, setUser] = useState(null);


  const handleRegister = async (userData) => {

    setLoading(true)

    try {


      const userDa = {
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        ...userData,
      }

      const data = await createClient(userDa)

      setLoading(false)
      //console.log(data.id.error);

      if (data.id.error) {

        Swal.fire({
          icon: 'error',
          title: 'Ups !',
          text: data.id.error + " tente outro email ou faça login",
        });
        return
      }
      // Show success alert
      console.log("User registered successfully!");
      Swal.fire({
        icon: 'success',
        title: 'Uhaa , Cadastrado!',
        text: 'Seu cadastro foi efectuado com sucesso, e você foi logado automáticamente.',
      });

      setFormData({
        address: "---------",
        email: "",
        name: "",
        password: "",
        phone: "--------"
      })

    } catch (error) {

      // Show error alert
      Swal.fire({
        icon: 'warning',
        title: 'Opah !',
        text: error.message,
      });

    }
  };





  const handleFormSubmit = (e) => {
    alert('s')
    e.preventDefault();

    // Collect form data
    const formData = {
      name: e.target.elements.name.value,
      // bi: e.target.elements.bi.value,
      // birthdate: e.target.elements.birthdate.value,
      phone: e.target.elements.phone.value,
      // province: e.target.elements.province.value,
      city: e.target.elements.address.value,
      email: e.target.elements.email.value,
      password: e.target.elements.password.value,
    };
    // Call backend to register user
    handleRegister(formData);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleClick = () => {
    handleRegister(formData);
  };


  return (
    <>
      <Header
        style={{ marginBottom: "5rem" }}
        nomee={nomee}
        emaill={emaill}
        cart={cart}
      />
      <div className="c  mx-auto body pt-4 bg-light">
        <div className="container ">
          <div className="row ">
            <div className="col-12  text-center "></div>
            <div className="col-12  ">
              <div className="text-center">
                <br />
                <h2>
                  <b>Olá, crie uma conta no Reputação 360</b>
                </h2>
                <p className="fw-light fw-400 fw-thin f-16">
                  Com você logado conseguimos oferecer um serviço melhor e mais
                  personalizado. Navegue logado e ajude outros milhões de
                  consumidores.
                </p>
                <br />
              </div>
              <div className="container my-auto form-c form">
                <center>

                  <div className="text-dark">
                    <div className="text-start mb-3 headc">
                      <b className="text-dark">Preencha o formulário abaixo</b>
                      <p>
                        <b className="text-danger">*</b>{" "}
                        <span className="text-secondary f-14">Todos os campos são obrigatórios</span>
                      </p>
                    </div>
                    <div className="titul">
                      <div className="d-flex gap-2">
                        <i className="bi text-success bi-person-circle"></i>{" "}
                        <b>Dados pessoais</b>
                      </div>
                    </div>
                    <div className="row text-start">
                      <div className="col-12 col-lg-66 my-2">
                        <label htmlFor="name" className="text-secondary f-12">
                          <b className="text-danger">*</b> Nome completo
                        </label>
                        <input
                          type="text"
                          className="form-control rounded-1"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                          placeholder="Digite seu nome e sobrenome"
                        />
                      </div>

                      <div className="col-12 my-2 hidden col-lg-6">
                        <label htmlFor="phone" className="text-secondary f-12">
                          <b className="text-danger">*</b> Telefone
                        </label>
                        <input
                          type="tel"
                          className="form-control rounded-1"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          placeholder="Digite seu telefone atual"
                        />
                      </div>

                      <div className="col-12 hidden my-2 col-lg-6">
                        <label htmlFor="address" className="text-secondary f-12">
                          <b className="text-danger">*</b> Endereço
                        </label>
                        <input
                          type="text"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                          required
                          className="form-control rounded-1"
                          placeholder="Digite sua cidade atual"
                        />
                      </div>

                      <div className="col-12 my-2 col-lg-6">
                        <label htmlFor="email" className="text-secondary f-12">
                          <b className="text-danger">*</b> E-mail
                        </label>
                        <input
                          required
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          className="form-control rounded-1"
                          placeholder="Digite seu melhor email"
                        />
                      </div>

                      <div className="col-12 my-2 col-lg-6">
                        <label htmlFor="password" className="text-secondary f-12">
                          <b className="text-danger">*</b> Crie uma senha
                        </label>
                        <input
                          type="password"
                          required
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          className="form-control rounded-1"
                          placeholder="Crie uma senha (min 8 caracteres)"
                          minLength={8}
                        />
                      </div>
                    </div>

                    <br />
                    <button
                      disabled={loading}
                      type="button" // Use 'button' instead of 'submit'
                      className="d-flex text-white w-100 btn btn-success justify-content-center rounded-1"
                      onClick={handleClick}
                    >
                      {
                        loading ? <span className="">Cadastrando...</span> : <span>Cadastrar</span>
                      }
                    </button>
                  </div>
                  <br /> 


                </center>
              </div>
              <br />
              <br />
              <div className="container form-c my-auto form">
                <center>
                  <>
                    <br />
                    <div className="pb-2">
                      <b className="text-dark">
                        É uma empresa e quer responder reclamações?
                      </b>
                      <br />
                      <span className="f-12 text-secondary">
                        Acesse a área da empresa para responder seus
                        consumidores
                      </span>
                    </div>
                    <ScrollToTopLink
                      to={"/cadastro/empresa"}
                      className="d-flex gap-2 text-decoration-none w-100 btn-google btn btn-outline-primary"
                    >
                      <span>Cadastre sua empresa</span>{" "}
                      <i className="bi my-auto bi-arrow-right-short"></i>
                    </ScrollToTopLink>
                  </>
                </center>
              </div>
              <br />
              <div className="text-center">
                <span>
                  Já possui uma conta ? <a href="/login">faça login</a>{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
      <Footer />
    </>
  );
};

export default CadastroConsumidor;
