import React, { useState, useEffect, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas'; // para converter canvas em imagem no PDF
import icon from "../../imgs/logos/new-logo.png";

const shortenUrl = async (longUrl) => {
  const response = await fetch(`https://cutt.ly/api/api.php?key=8c42e63b7d05604b1e3d902ebb9cb9c817f28&short=${encodeURIComponent(longUrl)}`, {
    method: 'GET'
  });

  if (response.ok) {
    const data = await response.json();
    if (data.url.status === 7) {
      return data.url.shortLink;  // URL encurtada
    } else {
      throw new Error('Erro ao encurtar o link.');
    }
  } else {
    throw new Error('Erro ao conectar à API do Cutt.ly.');
  }
};

const ProfileQRCode = ({ empresa }) => {
  const [shortenedUrl, setShortenedUrl] = useState('');
  const [loading, setLoading] = useState(false); // Estado para controlar o loading
  const [successMessage, setSuccessMessage] = useState(''); // Estado para a mensagem de sucesso
  const canvasRef = useRef(null);  // Usado para o QR code canvas
  const slug = empresa?.slug;  // Exemplo: 'joao-afonso-katombela'
  const baseURL = 'https://reputacao360.online/empresa';
  const profileUrl = `${baseURL}/${slug}?source=qr_code`; // Link com parâmetro de QR code

  useEffect(() => {
    const fetchShortenedUrl = async () => {
      try {
        const shortUrl = await shortenUrl(profileUrl);
        setShortenedUrl(shortUrl);
      } catch (error) {
        // console.error('Erro ao encurtar o URL:', error);
      }
    };

    fetchShortenedUrl();
  }, [profileUrl]);

  // Função para baixar o QR code como PDF
  const downloadPDF = async () => {
    setLoading(true); // Ativa o estado de loading
    setSuccessMessage(''); // Limpa a mensagem de sucesso

    const canvas = canvasRef.current.querySelector('canvas');
    html2canvas(canvas).then((canvasElement) => {
      const imgData = canvasElement.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: "portrait", // ou "landscape" se preferir
        unit: "mm", // unidade de medida
        format: [120, 160], // largura e altura do PDF em mm
        hotfixes: [] // correções adicionais, se necessárias
      });

      const pdfWidth = pdf.internal.pageSize.getWidth(); // Largura do PDF
      const pdfHeight = pdf.internal.pageSize.getHeight(); // Altura do PDF

      // Título do PDF (centralizado)
      pdf.setFontSize(16);
      const title = `Escaneie para avaliar`;
      const title2 = ` ${empresa.nomeEmpresa}`;
      const titleWidth = pdf.getTextWidth(title); // Largura do texto
      const title2Width = pdf.getTextWidth(title2); // Largura do texto
      const titleY = (pdfHeight - 120) / 2; // Posição Y para centralizar, ajustado para a nova altura
      const title2Y = (pdfHeight - 100) / 2; // Posição Y para centralizar, ajustado para a nova altura
      pdf.text(title, (pdfWidth - titleWidth) / 2, titleY); // Centraliza o texto
      pdf.text(title2, (pdfWidth - title2Width) / 2, title2Y); // Centraliza o texto

      // Adicionar o QR code ao PDF (centralizado)
      const imgWidth = 70; // Largura da imagem
      const imgHeight = 70; // Altura da imagem
      const imgX = (pdfWidth - imgWidth) / 2; // Posição X para centralizar
      const imgY = title2Y + 20; // Posição Y para a imagem logo abaixo do título
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth, imgHeight); // Adiciona a imagem

      // Adicionar o URL abaixo do QR code (centralizado)
      pdf.setFontSize(10);
      const url = baseURL + "/" + empresa.slug; // Cria a URL
      const urlWidth = pdf.getTextWidth(url); // Largura do URL
      const urlY = imgY + imgHeight + 20; // Posição Y para a URL logo abaixo do QR code
      pdf.text(url, (pdfWidth - urlWidth) / 2, urlY); // Centraliza o texto da URL

      // Baixar o PDF
      pdf.save(`${empresa.nomeEmpresa}_QRCode.pdf`);

      // Mensagem de sucesso
      setSuccessMessage('Download do QR Code concluído com sucesso!');
    }).finally(() => {
      setLoading(false); // Desativa o estado de loading
    });
  };

  return (
    <div className='text-center'>
      <div ref={canvasRef}>
        <QRCodeCanvas
          value={profileUrl}
          size={240}
          style={{ objectFit: "fill" }}
          className='qr'
          imageSettings={{
            src: icon,  // Caminho para o logo
            height: 40,
            width: 70,
            excavate: true,
          }}
        />
      </div>
      <br />
      <center>
        <button
          className='btn-sm btn btn-primary mx-auto'
          onClick={downloadPDF}
          disabled={loading} // Desabilita o botão enquanto o PDF está sendo gerado
        >
          {loading ? 'Gerando PDF...' : 'Baixar QR Code'}
        </button>
      </center>
      {successMessage && <p className="text-success mt-2">{successMessage}</p>} {/* Mensagem de sucesso */}
    </div>
  );
};

export default ProfileQRCode;
