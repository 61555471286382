import React from "react";
import { RenderEmpresa } from "../banner/render-empresa";

export const VerifiedCompanies = ({ todasEmpresas }) => (
  <div className="banner-container hidden">
    <h2 className="banner-title">Empresas Verificadas</h2>
    <div className="banner-grid">
      {todasEmpresas.map((empresa, index) => (
        <RenderEmpresa key={index} empresa={empresa} />
      ))}
    </div>
  </div>
);
