import { AxiosHttpClient } from "../api/axiosHttpClient";


const httpClient = new AxiosHttpClient()


// Função para criar um cliente
export const createClient = async (clientData) => {
  try {
    const response = await httpClient.post(`/clientes`, clientData);
    return response;
  } catch (error) {
    console.error('Erro ao criar cliente:', error);
    throw error;
  }
};

// Função para obter todos os clientes
export const getClients = async () => {
  try {
    const response = await httpClient.get(`/clientes`);
    return response;
  } catch (error) {
    console.error('Erro ao obter clientes:', error);
    throw error;
  }
};

// Função para obter um cliente por ID
export const getClientById = async (clientId) => {
  try {
    const response = await httpClient.get(`/clientes/${clientId}`);
    return response;
  } catch (error) {
    console.error('Erro ao obter cliente:', error);
    throw error;
  }
};

// Função para atualizar um cliente
export const updateClient = async (clientId, updatedData) => {
  try { 
    const response = await httpClient.put(`/clientes/${clientId}`, updatedData);
    return response;
  } catch (error) {
    console.error('Erro ao atualizar cliente:', error);
    throw error;
  }
};

// Função para excluir um cliente
export const deleteClient = async (clientId) => {
  try {
    const response = await httpClient.delete(`/clientes/${clientId}`);
    return response;
  } catch (error) {
    console.error('Erro ao excluir cliente:', error);
    throw error;
  }
};



// Função para fazer login
export const login = async (email, password) => {
  try {
    const response = await httpClient.post(`/clientes/login`, { email, password });

    // Armazena o token JWT no localStorage
    localStorage.setItem('token', response.token);

    // Retorna os dados do cliente
    return response;
  } catch (error) {
    console.error('Erro ao fazer login:', error);
    throw error.response ? error.response.data.message : 'Erro de conexão';
  }
};

// Função para verificar se o usuário está autenticado
export const isAuthenticated = () => {
  const token = localStorage.getItem('token');

  // Verifica se o token existe
  return token !== null;
};

// Função para obter o token JWT
export const getToken = () => {
  return localStorage.getItem('token');
};

// Função para fazer logout
export const logout = () => {
  // Remove o token do localStorage
  localStorage.removeItem('token');
};

// Função para configurar o cabeçalho Authorization para requisições
export const setAuthHeader = (axiosInstance) => {
  const token = getToken();

  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};