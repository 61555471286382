import React from "react";
import ScrollToTopLink from "../scrollTopLink";

export const LinkSection = () => (
  <div className="bg-light">
    <br /><br />
    <div className="container">
      <h5 className="fw-bolder"><b>Explore a R360</b></h5>
      <div className="mx-auto quadros w-md-75 w-lg-75">
        <div className="ppp">
          {[
            { to: "/reclamar", text: "Pesquise uma empresa", icon: "bi-search" },
            { to: "/cadastro/empresa", text: "Cadastre uma empresa", icon: "bi-building-add" },
            { href: "https://pleno.ao", text: "Domínios AO", icon: "bi-hdd-rack", target: "__blank" },
            { to: "/descontos", text: "Cupons de Desconto", icon: "bi-ticket-perforated" },
            { to: "/central-de-ajuda", text: "Central de Ajuda", icon: "bi-question-circle" },
            { to: "/reclamar", text: "Faça uma Reclamação", icon: "bi-megaphone" },
          ].map((link, index) => (
            <div className="my-2" key={index}>
              {link.href ? (
                <a href={link.href} target={link.target} className="text-decoration-none h-100 q rounded-2">
                  <span>{link.text}</span> <i className={`bi ${link.icon}`}></i>
                </a>
              ) : (
                <ScrollToTopLink to={link.to} className="q text-decoration-none rounded-2">
                  <span>{link.text}</span> <i className={`bi ${link.icon}`}></i>
                </ScrollToTopLink>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
    <br />
  </div>
);
