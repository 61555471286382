import axios from "axios";
import { API_URL } from "./eempresas";
import Cookies from "js-cookie";
import { AxiosHttpClient } from "../api/axiosHttpClient";

const httpClient = new AxiosHttpClient()


// Função para criar uma nova reclamação
export const criarReclamacao = async (reclamacao) => {
  try {
    const response = await httpClient.post("/reclamacoes", reclamacao);
    return response; // Retorna os dados da resposta
  } catch (error) {
    console.error('Erro ao criar reclamação:', error);
    throw error; // Repassa o erro para ser tratado onde a função é chamada
  }
};


// Função para obter todas as reclamações
export const obterReclamacoes = async () => {
  // Tenta obter as reclamações armazenadas nos cookies
  //const reclamacoesArmazenadas = Cookies.get('reclamacoes');

  // Se as reclamações estão disponíveis nos cookies, retorne-as
  //if (reclamacoesArmazenadas != 'undefined') {
  //return JSON.parse(reclamacoesArmazenadas);
  //}

  // Se não, faça a requisição à API
  try {
    const response = await httpClient.get("/reclamacoes");
    // Armazena os dados no cookie por 7 dias
    Cookies.set('reclamacoes', JSON.stringify(response), { expires: 7 });
    return response; // Retorna os dados da API
  } catch (error) {
    console.error('Erro ao obter reclamações:', error);
    throw error;
  }
};

// Função para responder a uma reclamação
export const responderReclamacao = async (id, resposta, owner) => {
  try {
    // Formato da resposta: { data: new Date(), texto: resposta, autor: "Nome do autor" }
    const respostaFormatada = {
      data: new Date(),
      texto: resposta,
      autor: owner
    };

    const response = await httpClient.put(`/reclamacoes/${id}/responder`, respostaFormatada);
    return response; // Retorna os dados da resposta
  } catch (error) {
    console.error('Erro ao responder reclamação:', error);
    throw error; // Repassa o erro para ser tratado onde a função é chamada
  }
};




// Função para obter uma reclamação por ID
export const obterReclamacaoPorId = async (id) => {
  // Tenta obter a reclamação armazenada nos cookies
  // //const reclamacaoArmazenada = Cookies.get(`reclamacao_${id}`);

  // Se a reclamação está disponível nos cookies, retorne-a
  /*if (reclamacaoArmazenada != "undefined") {
    return JSON.parse(reclamacaoArmazenada);
  }
*/
  // Se não, faça a requisição à API
  try {
    const response = await httpClient.get(`/reclamacoes/${id}`);
    // Armazena os dados no cookie por 7 dias
    // Cookies.set(`reclamacao_${id}`, JSON.stringify(response), { expires: 7 });
    return response; // Retorna os dados da API
  } catch (error) {
    console.error('Erro ao obter reclamação:', error);
    throw error;
  }
};


// Função para atualizar uma reclamação
export const atualizarReclamacao = async (id, atualizacao) => {
  try {
    const response = await httpClient.put(`/${id}`, atualizacao);
    return response; // Retorna os dados da resposta
  } catch (error) {
    console.error('Erro ao atualizar reclamação:', error);
    throw error;
  }
};

// Função para deletar uma reclamação
export const deletarReclamacao = async (id) => {
  try {
    const response = await httpClient.delete(`/${id}`);
    return response; // Retorna os dados da resposta
  } catch (error) {
    console.error('Erro ao deletar reclamação:', error);
    throw error;
  }
};


// Função para obter reclamações por empresaId
export const getComplaintsByCompanyId = async (empresaId) => {
  // Tenta obter as reclamações da empresa armazenadas nos cookies
 // const reclamacoesArmazenadas = Cookies.get(`reclamacoes_empresa_${empresaId}`);

  // Se as reclamações estão disponíveis nos cookies, retorne-as
  //if (reclamacoesArmazenadas) {
    //return JSON.parse(reclamacoesArmazenadas);
  //}

  // Se não, faça a requisição à API
  try {
    const response = await httpClient.get(`/reclamacoes/empresa/${empresaId}`);
    // Armazena os dados no cookie por 7 dias
    //Cookies.set(`reclamacoes_empresa_${empresaId}`, JSON.stringify(response), { expires: 7 });
    return response; // Retorna os dados da API
  } catch (error) {
    console.error("Erro ao obter reclamações:", error);
    throw error; // Propague o erro
  }
};
