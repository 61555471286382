import ScrollToTopLink from "../scrollTopLink";
import desc from "../../imgs/desc.png";

export function EmpresasDescontos() {
  return (
    <div className="container desconto">
      <div className="flex-row-reverse row">
        <div className="col-12 imm col-md-5">
          <img src={desc} alt="" className="my-auto  -100" />
        </div>
        <div className="col-12 tt col-md-7">
          <div className="my-auto">
            <h2 className="fw-bold">Encontre os melhores descontos</h2>
            <h5 className="text-secondary">
              Compre com desconto e em segurança nas empresas mais confiáveis
              e validadas pela R360 e clientes que já usaramseus serviços ou
              produtos
            </h5>

            <br />
            <ScrollToTopLink
              to={"/descontos"}
              className="btn rounded-1 btn-outline-dark"
            >
              Ver Todos os Descontos
            </ScrollToTopLink>
          </div>
        </div>
      </div>
    </div>
  )
}