import React, { useEffect, useState } from "react";
import "./banner.css";
import blog from "../../imgs/blog.png";
import AbreviarTexto from "../abreviarTexto";
import articles from "../../model/artigos";
import Pub from "../publicidade";
import { BannerCategorias, obterCategoriasUnicas } from "./banner-categoria";
import { getEmpresas } from "../../services/eempresas";
import { EmpresasDescontos } from "../home-components/empresas-com-descontos";
import { CardsDescsR360 } from "../home-components/card-descs-r360";
import { Partners } from "../home-components/partners";
import { RedukaContainer } from "../home-components/reduka-container";
import { SejaOuvido } from "../home-components/seja-ouvido";
import { LinkSection } from "../home-components/linkSection";
import { VerifiedCompanies } from "../home-components/verifiedCompanies";
import { CategorySection } from "../home-components/categorySection";
import { CategorySectionIndividual } from "../home-components/categorySectionIndividual";
import { HeaderBanner } from "../home-components/header-banner";
import EmpresaLoader from '../skeletons/empresa-loader'

const Banner = () => {
  const [todasEmpresas, setTodasEmpresas] = useState([]);
  const [empresasPorCategoria, setEmpresasPorCategoria] = useState([]);
  const [empresasPorCategoriaIndividual, setEmpresasPorCategoriaIndividual] = useState([]);
  const [loadingCategoria, setLoadingCategoria] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dadosEmpresas = await getEmpresas();
        setTodasEmpresas(dadosEmpresas);
        setTimeout(() => {
          setLoadingCategoria(false);
        }, 900);
      } catch (error) {
        console.error("Erro ao obter empresas:", error.message);
        setLoadingCategoria(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const carregarCategorias = async () => {
      try {
        const categoriasUnicas = await obterCategoriasUnicas();
        const categoriasAleatorias = categoriasUnicas
          .sort(() => 0.5 - Math.random())
          .slice(0, 4);

        const empresasFiltradas = categoriasAleatorias.map((categoria) => {
          const empresasCategoria = todasEmpresas
            .filter(
              (empresa) =>
                empresa.categoria === categoria && empresa.avaliacao > 1
            )
            .slice(0, 6); // Limita a 6 empresas por categoria

          return {
            categoria,
            empresas: empresasCategoria,
          };
        });

        setEmpresasPorCategoria(empresasFiltradas.filter(item => item.empresas.length > 0)); // Filtra categorias sem empresas
      } catch (error) {
        console.error("Erro ao carregar categorias:", error);
      }
    };

    if (!loadingCategoria) {
      carregarCategorias();
    }
  }, [todasEmpresas, loadingCategoria]);


  useEffect(() => {
    const carregarCategorias = async () => {
      try {


        const empresasFiltradas = todasEmpresas
          .filter(
            (empresa) =>
              empresa.tipo === "singular" && empresa.avaliacao > 1
          )
          .slice(0, 6);

        // console.log(empresasFiltradas)
        setEmpresasPorCategoriaIndividual(empresasFiltradas); // Filtra categorias sem empresas


      } catch (error) {
        console.error("Erro ao carregar categorias:", error);
      }
    };

    carregarCategorias();
  }, [todasEmpresas, loadingCategoria]);

  return (
    <>
      <HeaderBanner />
      <LinkSection />
      <br />
      <br />
      <br />
      <div className="container">
        <h3><b>O que procura ?</b></h3>
        <br />
      </div>
      <BannerCategorias />
      <br />
      <VerifiedCompanies todasEmpresas={todasEmpresas} />
      {loadingCategoria ? (
        <div className="empresas-loader">
          {[...Array(12)].map((_, index) => (
            <EmpresaLoader key={index} />
          ))}
        </div>
      ) : (
        <>
          <CategorySectionIndividual empresasPorCategoriaIndividual={empresasPorCategoriaIndividual} />
          <CategorySection empresasPorCategoria={empresasPorCategoria} />
        </>
      )}

      <br /><br /><br />
      <EmpresasDescontos />

      <br />
      <br />

      <Pub />
      <br />
      <br />
      <br />

      <br />
      <br />
      <br />

      <div className="container blog">
        <center>
          <img src={blog} className="logo-blog" alt="" />
        </center>
        <br />
        <br />

        <div className="lista-blog">
          <div className="gap-4 overflow-x-auto d-flex scroll-md">
            {articles.map((artigo, i) => (
              <div key={i} className="shadow-sm card-blog rounded-1 border-lightt">
                <img src={artigo.coverImage} alt="" className="logo-blog" />
                <div className="px-3 pb-3 bod">
                  <br />
                  <AbreviarTexto texto={artigo.title} largura={"390"} />

                  <p className="gap-2 pb-2 my-auto mt-1 d-flex f-14">
                    <span className="my-auto text-secondary">
                      {artigo.date}
                    </span>
                  </p>
                  <a href={"/blog/" + artigo.id} className="">
                    Ler matéria <i className="bi bi-arrow-right-short"></i>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>

        <br />
        <br />
      </div>

      <br />
      <br />
      <br />
      <br />
      <CardsDescsR360 />
      <br />
      <Partners />
      <br />
      <br />
      <RedukaContainer />
      <br />
      <SejaOuvido />
      <br />
      <br />
      <Pub />
    </>
  );
};

export default Banner;
