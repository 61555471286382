import "../App.css";
// Bootstrap CSS
// Bootstrap Bundle JS
import Header from "../components/header";
import Footer from "../components/footer";
import { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { useParams } from "react-router-dom";
import "../css/ranking.css";
import { Editor } from "@tinymce/tinymce-react";
import AvaliacaoComponent from "../components/avaliacaoComponent";
import Swal from "sweetalert2";
import ScrollToTopLink from "../components/scrollTopLink";
import EmpresaLoader from "../components/empLoader";
import Loader from "../components/loader";
import Pub from "../components/publicidade";
import StarRating from "../components/starts";
import { criarReclamacao, getComplaintsByCompanyId } from "../services/reclamacoes";
import { getEmpresaById, getEmpresaBySlug } from "../services/eempresas";
import { useSelector } from "react-redux";
import { getClients } from "../services/client-service";

const ReclamarEmpresa = ({ cart, nomee, emaill }) => {
  // const { user, handleLogout } = useContext(UserContext);

  const userFromRedux = useSelector((state) => state.user.client);
  const [user, setUser] = useState(userFromRedux);
  const [load, setLoader] = useState(false);

  const { empresa_slug } = useParams();

  // const empres = dadosEmpresas.filter((p) => p._id == empresa);
  // const empresaEscolhida = empres[0];
  // console.log(empresaEscolhida);

  const [empresaEscolhida, setEmpresaEscolhida] = useState(null);
  const [historia, setHistoria] = useState("")
  const [reclamacoesEmpresa, setReclamacoesEmpresa] = useState([]);



  // Função para atualizar o favicon
  const setFavicon = (url) => {
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      document.head.appendChild(newLink);
      link = newLink;
    }
    link.href = url;
  };

  // useEffect para atualizar o favicon quando a imagem de perfil da empresa for obtida
  useEffect(() => {
    if (empresaEscolhida && empresaEscolhida.logo) {
      setFavicon(empresaEscolhida.logo);
    }
  }, [empresaEscolhida]);

  useEffect(() => {
    const pegarEmpresa = async () => {
      try {
        // Substitua pela função que obtém uma empresa pelo ID
        const empresaData = await getEmpresaBySlug(empresa_slug);

        if (empresaData) {
          setEmpresaEscolhida({
            ...empresaData,
          });
          //console.log(empresaData)

          // Agora pegamos as reclamações da empresa
          const reclamacoesData = await getComplaintsByCompanyId(empresaEscolhida._id);

          setReclamacoesEmpresa(reclamacoesData);
        } else {
          console.error("Empresa não encontrada.");
        }
      } catch (error) {
        console.error("Erro ao pegar empresa:", error.message);
      }
    };


    pegarEmpresa();
  }, [empresa_slug]);

  // Função para calcular a média das classificações
  const calcularMediaClassificacoes = () => {
    const totalClassificacoes = reclamacoesEmpresa.reduce(
      (total, reclamacao) => total + reclamacao.classificacao,
      0
    );

    const mediaClassificacoes =
      reclamacoesEmpresa.length > 0
        ? totalClassificacoes / reclamacoesEmpresa.length
        : 0;

    return mediaClassificacoes.toFixed(1);
  };

  const [reclamacoesRespondidas, setReclamacoesRespondidas] = useState(0);

  const calcularReclamacoesRespondidas = () => {
    return reclamacoesEmpresa.filter(
      (reclamacao) => reclamacao.status === "respondido"
    ).length;
  };
  useEffect(() => {
    if (empresaEscolhida && reclamacoesEmpresa.length > 0) {
      const novaAvaliacao = calcularMediaClassificacoes();

      // Verifica se a nova avaliação é diferente da atual antes de atualizar o estado
      if (empresaEscolhida.avaliacao !== novaAvaliacao) {
        setEmpresaEscolhida((prevEmpresa) => ({
          ...prevEmpresa,
          avaliacao: novaAvaliacao,
        }));
      }

      // Obtém o número de reclamações respondidas
      const reclamacoesRespondidas = calcularReclamacoesRespondidas();

      // Atualiza o estado somente se o número de reclamações respondidas mudou
      if (reclamacoesRespondidas !== empresaEscolhida.reclamacoesRespondidas) {
        setReclamacoesRespondidas(reclamacoesRespondidas);
      }
    }
  }, [empresaEscolhida, reclamacoesEmpresa]);

  // const [avaliacaoUsuario, setAvaliacaoUsuario] = useState(null);

  // const handleAvaliacaoChange = (avaliacao) => {
  //   setAvaliacaoUsuario(avaliacao);
  // };

  document.title = `Reclamar de ${empresaEscolhida?.nomeEmpresa} | Reputação 360`;

  // Função auxiliar para obter o nome do mês
  const getMonthName = (monthIndex) => {
    const monthNames = [
      "janeiro",
      "fevereiro",
      "março",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro",
    ];
    return monthNames[monthIndex];
  };

  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()} de ${getMonthName(
    currentDate.getMonth()
  )} de ${currentDate.getFullYear()}`;


  useEffect(() => {
    // verificar login do usuario
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          // Substituindo a consulta ao Firestore pela chamada à API
          const querySnapshot = await getClients(); // Obtendo todos os clientes

          // Encontrar o usuário correspondente ao e-mail
          const userData = querySnapshot.find(client => client.email === user.email);

          if (userData) {
            // Se houver um documento correspondente, obter os dados
            const userDataResponse = {
              name: user.displayName ? user.displayName : userData.name,
              email: user.email,
              pictureUrl: user.photoURL,
              uid: user.uid,
              tel: user.phoneNumber ? user.phoneNumber : userData.phone,
              bi: userData.bi,
              city: userData.city,
              // Adicione outros campos conforme necessário
            };

            // Atualizar o estado do usuário com os dados
            // setUser(userDataResponse);

            // Salvar dados no localStorage
            localStorage.setItem('users', JSON.stringify(userDataResponse));
          } else {
            console.warn("Documento não encontrado na API para o e-mail do usuário.");
          }
        } catch (error) {
          console.error("Erro ao buscar dados da API:", error);
        }
      } else {
        // Se o usuário não estiver logado, defina o estado do usuário como null
        //setUser(null);
      }
    });

    // Função para obter dados das empresas

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);


  const [formData, setFormData] = useState({
    classificacao: 0,
    solicitarNovamente: null,
    mostrarNome: false,
    titulo: "-----------------",
    anexos: [], // Certifique-se de inicializar como um array vazio
  });

  const handleAvaliacaoChange = (avaliacao) => {
    setFormData({
      ...formData,
      classificacao: avaliacao,
    });
  };

  const handleSolicitacaoChange = (solicitacao) => {
    setFormData({
      ...formData,
      solicitarNovamente: solicitacao,
    });
  };

  const handleMostrarNome = (decisao) => {
    setFormData({
      ...formData,
      mostrarNome: decisao,
    });
  };

  const handleTituloChange = (e) => {
    setFormData({
      ...formData,
      titulo: e.target.value,
    });
  };

  const handleHistoriaChange = (e) => {
    setFormData({
      ...formData,
      historia: e.target.value,
    });
  };

  const handleAnexosChange = (e) => {
    const files = e.target.files;
    setFormData({
      ...formData,
      anexos: Array.from(files), // Certifique-se de converter para um array
    });
  };
  const handleEnviarReclamacao = async () => {
    setLoader(true);
    try {
      // Verificar se os campos obrigatórios estão preenchidos
      if (
        formData.solicitarNovamente === null ||
        historia.trim() === ""
      ) {
        setLoader(false);
        Swal.fire({
          icon: "warning",
          title: "Campos obrigatórios não preenchidos",
          text: "Por favor, preencha todos os campos obrigatórios.",
        });
        return; // Interrompe o envio se algum campo estiver vazio
      }

      // Filtra apenas os anexos que são imagens
      const imagens = formData.anexos.filter(anexo =>
        anexo.type.startsWith("image/")
      );

      // Upload de arquivos de imagem para o Storage
      const anexosURLs = await Promise.all(
        imagens.map(async (imagem) => {
          const storageRef = firebase.storage().ref();
          const fileRef = storageRef.child(`reclamacoes/${imagem.name}`); // Pasta "reclamacoes"
          await fileRef.put(imagem);
          return fileRef.getDownloadURL();
        })
      );

      // Prepara os dados da reclamação
      const reclamacaoData = {
        ...formData,
        anexos: anexosURLs, // Apenas as URLs das imagens
        empresaId: empresaEscolhida._id,
        nomeEmpresa: empresaEscolhida?.nomeEmpresa,
        cliente: user.name,
        historia: historia,
        emailCliente: user.email,
        status: "nao-respondido",
        quando: formattedDate,
        photo: user?.pictureUrl,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        // Adicione outros campos necessários, como data, usuário, etc.
      };

      // Chama a função utilitária para criar a reclamação
      await criarReclamacao(reclamacaoData);

      Swal.fire({
        icon: "success",
        title: "Reclamação enviada com sucesso!",
        text: `Sua reclamação foi registrada com sucesso, ${user.name}!`,
      });

      setLoader(false);
      // Limpa os campos do formulário após o envio bem-sucedido
      setFormData({
        classificacao: 0,
        solicitarNovamente: null,
        titulo: "",
        historia: "",
        anexos: [],
      });
    } catch (error) {
      setLoader(false);
      console.error("Erro ao enviar reclamação:", error);
      Swal.fire({
        icon: "error",
        title: "Erro ao enviar reclamação",
        text: "Ocorreu um erro ao enviar sua reclamação. Tente novamente mais tarde.",
      });
    }
  };


  useEffect(() => {
    // verificar login do usuario
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          // Substituindo a consulta ao Firestore pela chamada à API
          const querySnapshot = await getClients(); // Obtendo todos os clientes

          // Encontrar o usuário correspondente ao e-mail
          const userData = querySnapshot.find(client => client.email === user.email);

          if (userData) {
            // Se houver um documento correspondente, obter os dados
            const userDataResponse = {
              name: user.displayName ? user.displayName : userData.name,
              email: user.email,
              pictureUrl: user.photoURL,
              uid: user.uid,
              tel: user.phoneNumber ? user.phoneNumber : userData.phone,
              bi: userData.bi,
              city: userData.city,
              // Adicione outros campos conforme necessário
            };

            // Atualizar o estado do usuário com os dados
            setUser(userDataResponse);

            // Salvar dados no localStorage
            localStorage.setItem('users', JSON.stringify(userDataResponse));
          } else {
            console.warn("Documento não encontrado na API para o e-mail do usuário.");
          }
        } catch (error) {
          console.error("Erro ao buscar dados da API:", error);
        }
      } else {
        // Se o usuário não estiver logado, defina o estado do usuário como null
        // setUser(null);
      }
    });



    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, [firebase.auth()]);



  return (
    <div className="w-100">
      <Header
        style={{ marginBottom: "5rem" }}
        nomee={nomee}
        emaill={emaill}
        cart={cart}
      />

      <div className="s bg-light">
        <div className="bg-white  pt-5 mt-sm-5 mt-md-0 pt-lg-3 rounded-3 container pb-3">
          <div className="d-flex justify-content-between mt-sm-5 mt-md-0 gap-3">
            <div className="d-flex gap-3">
              {empresaEscolhida?.nomeEmpresa != null ? (
                <>
                  <img
                    src={empresaEscolhida?.logo}
                    alt=""
                    className="logo-rec"
                  />
                  <div className="my-auto">
                    <ScrollToTopLink
                      to={"/empresa/" + empresaEscolhida.slug}
                      className={"text-decoration-none"}
                    >
                      <h5 className="f-reg">{empresaEscolhida?.nomeEmpresa}</h5>
                    </ScrollToTopLink>
                    <div className="d-flex gap-2">
                      <StarRating rating={empresaEscolhida.avaliacao} />
                      <h4>{empresaEscolhida.avaliacao}</h4>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <EmpresaLoader />
                </>
              )}
            </div>
            <a
              href="/reclamar"
              className="my-auto alterar text-decoration-none"
            >
              Alterar empresa
            </a>
          </div>
        </div>
        <br />

        <br />
        <div className="container my-auto form-c form">
          <center>
            <>
              <div className="text-dark py-2">
                <div className="text-center mb-3 headc">
                  {/* <img
                    src={user.pictureUrl}
                    style={{ height: "96px" }}
                    className=""
                    alt="User"
                  /> */}

                  <h2 className="text-dark">
                    Vamos começar.{" "}
                    <b className="text-success">Conte sua história</b>
                  </h2>

                  <p>
                    <span className="text-secondary f-14">
                      Descreva o seu problema com a empresa.
                    </span>
                  </p>
                </div>

                <div className="titul mt-3">
                  <div className="d-flex f-reg gap-2">
                    <i className="bi text-success bi-star"></i>
                    <b>
                      Classifique a história / Empresa{" "}
                      <span className="text-danger">*</span>
                    </b>
                  </div>
                </div>

                <br />
                <div className="col-12 text-center my-2">
                  <AvaliacaoComponent
                    className=""
                    onAvaliacaoChange={handleAvaliacaoChange}
                  />
                </div>
                <hr />
                <div className="col-12 my-2 ">
                  <div className="titul mt-3">
                    <div className="d-flex f-reg gap-2">
                      <i className="bi text-success bi-hand-thumbs-up"></i>
                      <b>
                        Solicitaria novamente esta Empresa
                        <span className="text-danger">*</span>
                      </b>
                    </div>
                  </div>{" "}
                  <label htmlFor="" className="text-secondary f-12">
                    Para o caso de resolverem o seu problema, pode voltar a
                    responder mais tarde
                  </label>
                  <br />
                  <br />
                  <div className="d-flex justify-content-around">
                    <label htmlFor="sim" className="f-18">
                      <input
                        type="radio"
                        checked={formData.solicitarNovamente === "sim"}
                        onChange={() => handleSolicitacaoChange("sim")}
                        id="sim"
                      />
                      Sim
                    </label>

                    <label htmlFor="nao" className="f-18">
                      <input
                        type="radio"
                        checked={formData.solicitarNovamente === "nao"}
                        onChange={() => handleSolicitacaoChange("nao")}
                        id="nao"
                      />
                      Não
                    </label>
                  </div>
                </div>
                <hr />
                <div className="titul hidden">
                  <div className="d-flex gap-2">
                    <i className="bi text- f-reg bi-megaphone"></i>{" "}
                    <b>Título da história</b>
                  </div>
                </div>
                <div className="row  text-start">
                  <div className="col-12 hidden my-2">
                    <input
                      type="text"
                      className="form-control rounded-1"
                      placeholder="Escolha um título para sua história"
                      value={formData.titulo}
                      onChange={handleTituloChange}
                    />
                  </div>
                  <br />
                  <div className="col-12 col-lg-12 my-2">
                    <label htmlFor="" className=" f-reg">
                      Conte sua história ( Optional )
                    </label>

                    <textarea
                      name=""
                      id=""
                      placeholder={`Descreva sua experiência com produtos ou serviços da ${empresaEscolhida?.nomeEmpresa}`}
                      className="w-100 form-control mt-1"
                      cols="30"
                      rows="3"
                      maxLength={1000}
                      value={historia}
                      onChange={(e) => setHistoria(e.target.value)}
                    ></textarea>

                    <div className="alert alert-info alert-sm f-12 p-2 mt-2">
                      Nunca inclua dados pessoais no texto. A empresa receberá
                      seus dados junto com a reclamação.
                    </div>
                  </div>

                  <div className="titul mt-3">
                    <div className="d-flex f-reg gap-2">
                      <i className="bi text-success bi-archive"></i>
                      <b>
                        Anexos{" "}
                        <span className="text-secondary">(Opcional)</span>
                      </b>
                    </div>
                  </div>

                  <br />
                  <div className="col-12 my-2 ">
                    <label htmlFor="" className="text-secondary f-12">
                      No máximo 3 ficheiros
                    </label>
                    <input
                      type="file"
                      className="form-control rounded-1"
                      onChange={handleAnexosChange}
                      multiple
                    />
                    <br />
                  </div>

                  <div className="col-12 my-2 ">
                    <div className="titul mt-3">
                      <div className="d-flex f-reg gap-2">
                        <i className="bi text-success bi-person-circle"></i>
                        <b>
                          Deseja mostrar seu nome na avaliação ?
                          <span className="text-danger">*</span>
                        </b>
                      </div>
                    </div>{" "}
                    {/* {formData.mostrarNome ? 'sim' : 'nao'} */}
                    <label htmlFor="" className="text-secondary f-12">
                      Nós respeitamos a sua privacidade, no entanto deixamos a
                      sua escolha sobre a exposição do seu nome
                    </label>
                    <br />
                    <br />
                    <div className="d-flex justify-content-around">
                      <label htmlFor="simMostrar" className="f-18">
                        <input
                          type="radio"
                          checked={formData.mostrarNome === true}
                          onChange={() => handleMostrarNome(true)}
                          id="simMostrar"
                        />
                        Sim
                      </label>

                      <label htmlFor="naoMostrar" className="f-18">
                        <input
                          type="radio"
                          checked={formData.mostrarNome === false}
                          onChange={() => handleMostrarNome(false)}
                          id="naoMostrar"
                        />
                        Não
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              {user ? (
                <button
                  disabled={load}
                  onClick={handleEnviarReclamacao}
                  className="d-flex text-white w-100  btn btn-success justify-content-center rounded-1"
                >
                  {load ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <span>Enviar Reclamação</span>
                  )}
                </button>
              ) : (
                <>
                  <center>
                    <i className="bi bi-exclamation-triangle"></i>
                    <p className="text-secondary">
                      Faça{" "}

                      <ScrollToTopLink to={"/login"}>login</ScrollToTopLink>{" "}
                      ou cadastre se para fazer uma reclamação ou avaliação!
                    </p>
                  </center>
                </>
              )}
            </>
          </center>

          <br />
          <br />
        </div>
        <br />

        <Pub />

        <br />

        <Footer />
      </div>
    </div>
  );
};

export default ReclamarEmpresa;
