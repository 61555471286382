import "../../App.css";
// Bootstrap CSS
// Bootstrap Bundle JS
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../userContext";
import firebase from "firebase/compat/app";
import { useParams } from "react-router-dom";
import "../../css/ranking.css";

import "./style.css";
import r360 from "../../imgs/r360.png";
import AbreviarTexto from "../../components/abreviarTexto";
// import dadosEmpresas from "../../model/empresas";
import ScrollToTopLink from "../../components/scrollTopLink";
import obterDadosDoFirebase from "../../model/empresas2";
import EmpresaLoader from "../../components/empLoader";
import ProfileCard from "../../components/PerfilEmp";
import Comment from "../../components/skeletons/comment";
import ReclamacaoItem from "../../components/reclamacaoComponent/reclamacaoComponent";
import Pub from "../../components/publicidade";
import StarRating from "../../components/starts";
import { getEmpresaBySlug } from "../../services/eempresas";
import { getComplaintsByCompanyId } from "../../services/reclamacoes";
import { Helmet } from "react-helmet";
import ProfileQRCode from "../../components/profile-qrcode/profile-qrcode"

const PerfilEmpresa = ({ cart, nomee, emaill }) => {
  const { user, handleLogout } = useContext(UserContext);

  const { empresa_slug } = useParams();

  const [empresaEscolhida, setEmpresaEscolhida] = useState(null);
  const [reclamacoesEmpresa, setReclamacoesEmpresa] = useState([]);


  // Função para atualizar o favicon
  const setFavicon = (url) => {
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      document.head.appendChild(newLink);
      link = newLink;
    }
    link.href = url;
  };

  // useEffect para atualizar o favicon quando a imagem de perfil da empresa for obtida
  useEffect(() => {
    if (empresaEscolhida && empresaEscolhida.logo) {
      setFavicon(empresaEscolhida.logo);
    }
  }, [empresaEscolhida]);


  useEffect(() => {
    const pegarEmpresa = async () => {
      try {
        // Substitua pela função que obtém uma empresa pelo ID
        const empresaData = await getEmpresaBySlug(empresa_slug);

        if (empresaData) {
          setEmpresaEscolhida({
            ...empresaData,
          });
          //console.log(empresaData)

          // Agora pegamos as reclamações da empresa
          const reclamacoesData = await getComplaintsByCompanyId(empresaData._id);

          setReclamacoesEmpresa(reclamacoesData);

        } else {
          console.error("Empresa não encontrada.");
        }
      } catch (error) {
        console.error("Erro ao pegar empresa:", error.message);
      }
    };

    pegarEmpresa();
  }, [empresa_slug]);


  // Função para calcular a média das classificações
  const calcularMediaClassificacoes = () => {
    const totalClassificacoes = reclamacoesEmpresa.reduce(
      (total, reclamacao) => total + reclamacao.classificacao,
      0
    );

    const mediaClassificacoes =
      reclamacoesEmpresa.length > 0
        ? totalClassificacoes / reclamacoesEmpresa.length
        : 0;

    return mediaClassificacoes.toFixed(1);
  };

  const [reclamacoesRespondidas, setReclamacoesRespondidas] = useState(0);

  const calcularReclamacoesRespondidas = () => {
    return reclamacoesEmpresa.filter(
      (reclamacao) => reclamacao.status === "respondido"
    ).length;
  };
  useEffect(() => {
    if (empresaEscolhida && reclamacoesEmpresa.length > 0) {
      // Calcule a nova avaliação sem alterar o estado diretamente
      const novaAvaliacao = calcularMediaClassificacoes();

      // Atualize o estado de avaliação apenas se a nova avaliação for diferente
      if (novaAvaliacao !== empresaEscolhida.avaliacao) {
        setEmpresaEscolhida((prevEmpresa) => ({
          ...prevEmpresa,
          avaliacao: novaAvaliacao,
        }));
      }

      // Obtém o número de reclamações respondidas
      const reclamacoesRespondidas = calcularReclamacoesRespondidas();

      // Atualiza o estado com o número de reclamações respondidas
      setReclamacoesRespondidas(reclamacoesRespondidas);
    }
  }, [reclamacoesEmpresa, empresaEscolhida]);

  const TotalReclamacoesRespondidas = () => {
    const totalReclamacoes = reclamacoesEmpresa.length;
    return ((reclamacoesRespondidas / totalReclamacoes) * 100).toFixed(1);
  };

  // const empres = dadosEmpresas.filter((p) => p._id === empresaid);
  // const empresaEscolhida = empres[0];
  // console.log(empresaEscolhida);

  // const rec = Reclamacoes.filter((p) => p._id == empresaid);
  // const reclamacoesEmpresa = rec;
  // console.log(reclamacoesEmpresa);

  document.title = empresaEscolhida ? `${empresaEscolhida?.nomeEmpresa} | Reputação 360` : "Reputação 360 ";

  useEffect(() => {
    // Adicione um listener para o estado da autenticação
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        // Se não houver usuário autenticado, redirecione para a página de login

        const userData = {
          name: "",
          email: "",
          pictureUrl: "",
          tel: "",
          uid: "",
        };

        localStorage.setItem("users", JSON.stringify(userData));
      }
    });

    // Retorne uma função de limpeza para remover o listener quando o componente for desmontado
    return unsubscribe;
  }, []);

  const [dadosEmpresas, setDadosEmpresas] = useState([]);

  useEffect(() => {
    const ordenarEmpresas = async () => {
      try {
        const dadosEmpresas = await obterDadosDoFirebase();

        setDadosEmpresas(dadosEmpresas);
      } catch (error) {
        console.error("Erro ao ordenar empresas:", error.message);
      }
    };

    ordenarEmpresas();
  }, []);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      setShowModal(true);
      localStorage.setItem("hasVisited", true);
    }

  }, []);


  const [use, setUser] = useState([]);

  useEffect(() => {
    // Obtém o valor de 'users' do local storage quando o componente for montado
    const userString = localStorage.getItem("users");
    if (userString) {
      const user = JSON.parse(userString);
      setUser(user);
    } else {
      const userData = {
        name: "",
        email: "",
        pictureUrl: "",
        tel: "",
      };
      setUser(userData);
    }
  }, []);

  const [backgroundImage, setBackgroundImage] = useState(0);
  const images = ["a1.jpg", "a7.jpg", "a3.jpg"];

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const backgroundStyle = {
    backgroundImage: `url(${empresaEscolhida?.capa})`,
    backgroundSize: "100% auto",
    backgroundPosition: "center center",
    // filter: "brightness(75%)",
    position: "relative",
    height: "12rem",
  };

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  // Mapeia a nota da empresa para a largura da barra de progresso
  const larguraProgressBar = (empresaEscolhida?.avaliacao / 5) * 100;

  // Função para calcular os percentuais de pessoas que solicitariam novamente e não solicitariam
  const calcularPercentuaisSolicitariamNovamente = () => {
    const totalReclamacoes = reclamacoesEmpresa.length;

    const solicitariamNovamente = reclamacoesEmpresa.filter(
      (reclamacao) => reclamacao.solicitarNovamente === "sim"
    ).length;

    const naoSolicitariamNovamente = reclamacoesEmpresa.filter(
      (reclamacao) => reclamacao.solicitarNovamente === "nao"
    ).length;

    const percentualSolicitariam =
      (solicitariamNovamente / totalReclamacoes) * 100;
    const percentualNaoSolicitariam =
      (naoSolicitariamNovamente / totalReclamacoes) * 100;

    return {
      percentualSolicitariam,
      percentualNaoSolicitariam,
    };
  };

  const mediaClassificacoes = calcularMediaClassificacoes();
  const percentuaisSolicitariam = calcularPercentuaisSolicitariamNovamente();

  const [reclamacoesParaExibir, setReclamacoesParaExibir] = useState(4);

  const handleVerMais = () => {
    setReclamacoesParaExibir((prevCount) => prevCount + 4);
  };

  const handleVerMenos = () => {
    setReclamacoesParaExibir((prevCount) => Math.max(prevCount - 4, 4));
  };

  return (
    <div className="w-100 bg-light">
      {/*  */}
      {/* <Navba/> */}
      <Helmet>
        <title>{empresaEscolhida?.nomeEmpresa}</title>
        <meta property="og:title" content={empresaEscolhida?.title} />
        <meta property="og:description" content={empresaEscolhida?.sobre} />
        <meta property="og:image" content={empresaEscolhida?.logo} />
        <meta property="og:url" content={'https://reputacao360.online/empresa/' + empresaEscolhida?.slug} />
      </Helmet>

      <Header
        style={{ marginBottom: "5rem" }}
        nomee={nomee}
        emaill={emaill}
        cart={cart}
      />

      <div className="banner_perfil l" style={backgroundStyle}>
        <div className="foto-perfil-empresa">
          <img src={empresaEscolhida?.logo} alt="" />
        </div>
      </div>
      <div className=" bg-white border-bb">
        <div className="dados-empresa mt-4 py-3 mt-md-0 container">
          <div className="row">
            <div className="col-12 col-md-2"></div>
            <div className="col-12 ps-md-4 pt-3  pt-sm-0 col-md-8">
              {empresaEscolhida?.nomeEmpresa != null ? (
                <>
                  <b className="f-20 mt-5 f-reg">
                    {empresaEscolhida?.nomeEmpresa}
                  </b>
                  <br />
                  <div className="d-flex gap-2">
                    <StarRating rating={empresaEscolhida?.avaliacao} />
                    <span className="my-auto">&middot; </span>{" "}
                    <h4>{empresaEscolhida?.avaliacao}</h4>
                  </div>

                  {empresaEscolhida?.selo === true ? (
                    <p className="d-flex text-secondary gap-2">
                      <img src={r360} alt="" className="icon-empresa" />{" "}
                      <span className="text-dark">
                        Verificado pela <b>R360</b>
                      </span>
                    </p>
                  ) : null}
                  <div className="d-flex hidden gap-4 f-14 mt-2 flex-wrap">
                    <p className="d-flex text-secondary gap-2">
                      <i className="bi bi-calendar"></i>
                      {empresaEscolhida?.quando}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <EmpresaLoader />
                </>
              )}
            </div>
            <div className="col-12 text-center-md d-flex mt-3 mt-md-auto col-md-2">
              <ScrollToTopLink
                to={`/reclamar/${empresaEscolhida?.slug}`}
                className="btn btn-danger m-auto rec-b  gap-2 d-flex"
              >
                <i className="bi bi-megaphone"></i> Avaliar
              </ScrollToTopLink>
            </div>
          </div>
        </div>
        <div className="opcoes bg-white border-b-t py-2">
          <div className="d-flex justify-content-center my-auto gap-4 overflow-x-auto">
            <p className="text-primary gap-2 d-flex">
              <span className="uppercase text-uppercase">{empresaEscolhida?.tipo ? empresaEscolhida?.tipo + ' / ' : 'Empresa / '}</span> {empresaEscolhida?.nomeEmpresa}
            </p>
          </div>
        </div>
      </div>

      <br />
      <br />
      <div className="tabela-infoo container-fluid">
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-3 my-3">
            <h6 className="f-reg">
              <b>{empresaEscolhida?.nomeEmpresa} é confiável ?</b>
            </h6>

            <br />
            <div className="card-sobre-empresa border-1 bg-white ">
              <div className="p-3">
                <b className="text-dark m-3 f-reg">Reputação</b>
              </div>

              <div className="aval ">
                <div
                  className={` p-4 ${empresaEscolhida?.avaliacao >= 2 &&
                    empresaEscolhida?.avaliacao <= 3.5
                    ? "regular"
                    : ""
                    } ${empresaEscolhida?.avaliacao >= 3.6 &&
                      empresaEscolhida?.avaliacao <= 5
                      ? "otimo"
                      : ""
                    } ${empresaEscolhida?.avaliacao >= 1.1 &&
                      empresaEscolhida?.avaliacao <= 1.9
                      ? "pessimo"
                      : ""
                    }  ${empresaEscolhida?.avaliacao <= 1 ? "nao-recomendado" : ""
                    } 
                
                  `}
                >
                  <div className="d-flex">
                    {/* {empresaEscolhida?.selo === true ? (
                      <img src={r360} alt="" className="logo-reputacao" />
                    ) : empresaEscolhida?.avaliacao >= 5.0 &&
                      empresaEscolhida?.avaliacao <= 6.9 ? (
                      <img src={regular} alt="" className="logo-reputacao" />
                    ) : empresaEscolhida?.avaliacao >= 7.0 &&
                      empresaEscolhida?.avaliacao <= 10.0 ? (
                      <img src={otimo} alt="" className="logo-reputacao" />
                    ) : empresaEscolhida?.avaliacao >= 3.0 &&
                      empresaEscolhida?.avaliacao <= 4.9 ? (
                      <img src={ruim} alt="" className="logo-reputacao" />
                    ) : empresaEscolhida?.avaliacao <= 2.9 ? (
                      <img
                        src={naorecomendado}
                        alt=""
                        className="logo-reputacao"
                      />
                    ) : null} */}

                    <div className="container">
                      {/* <h5>
                        {empresaEscolhida?.selo ? (
                          <b className="f-reg f-16">R360</b>
                        ) : empresaEscolhida?.avaliacao >= 5.0 &&
                          empresaEscolhida?.avaliacao <= 6.9 ? (
                          <b className="f-reg">REGULAR</b>
                        ) : empresaEscolhida?.avaliacao >= 7.0 &&
                          empresaEscolhida?.avaliacao <= 10.0 ? (
                          <b className="f-reg">ÓTIMO</b>
                        ) : empresaEscolhida?.avaliacao >= 3.0 &&
                          empresaEscolhida?.avaliacao <= 4.9 ? (
                          <b className="f-reg">RUÍM</b>
                        ) : empresaEscolhida?.avaliacao <= 2.9 ? (
                          <b className="f-reg">NÃO RECOMENDADO</b>
                        ) : (
                          <b className="f-reg">SEM DADOS </b>
                        )}
                      </h5> */}
                      <div className="gap-2 d-flex">
                        <span className="text-secondary">Rating:</span>
                        <StarRating rating={empresaEscolhida?.avaliacao} />
                      </div>
                      <div className="d-flex gap-2">
                        <h2 className="f-reg">{empresaEscolhida?.avaliacao}</h2>
                        <span className="my-auto text-secondary"> / 5</span>
                      </div>
                    </div>
                  </div>
                  <div className="mx-auto my-2 border-1 rounded-1 text-center bg-white p-3">
                    <span className="f-12">Avaliações</span>
                    <b className="d-flex gap-2 f-reg mx-auto justify-content-center">
                      <i className="bi bi-megaphone"></i>{" "}
                      {reclamacoesEmpresa?.length}
                    </b>
                  </div>
                  {empresaEscolhida?.avaliacao <= 2.5 && (
                    <>
                      <hr />
                      <div className="d-flex gap-2">
                        <i className="bi f-reg bi-exclamation-circle"></i>
                        <p>
                          Média de avaliações muito baixa, esta empresa pode ser
                          nova ou tem recebido poucas avaliações
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <br />
              <div className="p-3">
                <b className="f-re">Voltariam a fazer negócios</b>
                <br />
                <div className="d-flex mt-2 gap-2">
                  <div
                    className="progress my-auto w-100"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      className={`progress-bar ${percentuaisSolicitariam.percentualSolicitariam.toFixed(
                        1
                      ) <= 2
                        ? "bg-danger"
                        : percentuaisSolicitariam.percentualSolicitariam.toFixed(
                          1
                        ) >= 2.1 &&
                          percentuaisSolicitariam.percentualSolicitariam.toFixed(
                            1
                          ) <= 4
                          ? "bg-warning"
                          : "bg-success2"
                        } `}
                      style={{
                        width:
                          percentuaisSolicitariam.percentualSolicitariam.toFixed(
                            1
                          ) + "%",
                      }}
                    ></div>
                  </div>
                  {empresaEscolhida?.avaliacao != null ? (
                    <span className="f-reg my-auto">
                      {percentuaisSolicitariam.percentualSolicitariam.toFixed(
                        1
                      )}
                      %
                    </span>
                  ) : (
                    <span className="f-reg my-auto">0 </span>
                  )}
                </div>
              </div>

              <div className="p-3">
                <b className="f-re">Reclamações Respondidas</b>
                <br />
                <div className="d-flex mt-2 gap-2">
                  <div
                    className="progress my-auto w-100"
                    role="progressbar"
                    aria-valuenow={TotalReclamacoesRespondidas()}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      className={`progress-bar bg-success2 `}
                      style={{
                        width: `${TotalReclamacoesRespondidas()}%`,
                      }}
                    ></div>
                  </div>
                  <span className="f-reg my-auto">
                    {empresaEscolhida?.avaliacao != null ? (
                      <span className="f-reg my-auto">
                        {TotalReclamacoesRespondidas()}%
                      </span>
                    ) : (
                      <span className="f-reg my-auto">0 </span>
                    )}
                  </span>
                </div>
              </div>
              <div className="p-3">
                <b className="f-re">Nota de consumidores</b>
                <br />
                <div className="d-flex mt-2 gap-2">
                  <div
                    className="progress my-auto w-100"
                    role="progressbar"
                    aria-valuenow={larguraProgressBar}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    <div
                      className={`progress-bar ${empresaEscolhida?.avaliacao <= 2
                        ? "bg-danger"
                        : empresaEscolhida?.avaliacao >= 2.1 &&
                          empresaEscolhida?.avaliacao <= 3.9
                          ? "bg-warning"
                          : "bg-success2"
                        } `}
                      style={{ width: `${larguraProgressBar}%` }}
                    ></div>
                  </div>
                  {empresaEscolhida?.avaliacao != null ? (
                    <span className="f-reg my-auto">
                      {empresaEscolhida?.avaliacao}
                    </span>
                  ) : (
                    <span className="f-reg my-auto">0 </span>
                  )}
                </div>
              </div>
            </div>
            <br />
            <div className="quem-viu-md">
              <br />
              <hr />
              <br />

              <br />
              <div className="card-sobre-empresa border-1 bg-white p-3">
                <b className="text-dark">
                  Quem viu {empresaEscolhida?.nomeEmpresa} também viu:
                </b>
                <br />
                <br />

                <div className="listas-lojas mb-3  d-flex gap-3 overflow-x-auto listas-descontos">
                  {dadosEmpresas.length != 0 ? (
                    <>
                      {dadosEmpresas
                        .filter(
                          (empresa) => empresa._id !== empresaEscolhida?.slug
                        )
                        .map((empresa) => (
                          <a
                            key={empresa._id}
                            href={`/empresa/${empresa.slug}`}
                            className="card-loja text-decoration-none text-dark text-center rounded-1 border-lightt p-3 shadow-sm"
                          >
                            <img
                              src={empresa.logo}
                              alt=""
                              className="logo-empresa"
                            />
                            <div className="bod">
                              <AbreviarTexto
                                texto={empresaEscolhida?.nomeEmpresa}
                                largura={"190"}
                              />

                              <p className="d-flex justify-content-center mt-1 my-auto gap-2 f-12">
                                <AbreviarTexto
                                  texto={empresaEscolhida?.enderecoEmpresa}
                                  largura={"300"}
                                  className="my-auto text-secondary"
                                ></AbreviarTexto>
                              </p>
                              <hr />

                              {/* <div className="d-flex gap-2 justify-content-center">
                                {empresaEscolhida?.avaliacao >= 5.0 &&
                                empresaEscolhida?.avaliacao <= 6.9 ? (
                                  <img
                                    src={regular}
                                    alt=""
                                    className="icon-empresa"
                                  />
                                ) : empresaEscolhida?.avaliacao >= 7.0 &&
                                  empresaEscolhida?.avaliacao <= 10.0 ? (
                                  <img
                                    src={otimo}
                                    alt=""
                                    className="icon-empresa"
                                  />
                                ) : empresaEscolhida?.avaliacao >= 3.0 &&
                                  empresaEscolhida?.avaliacao <= 4.9 ? (
                                  <img
                                    src={ruim}
                                    alt=""
                                    className="icon-empresa"
                                  />
                                ) : empresaEscolhida?.avaliacao <= 2.9 ? (
                                  <img
                                    src={naorecomendado}
                                    alt=""
                                    className="icon-empresa"
                                  />
                                ) : null}
                                <h4 className="f-reg my-auto">
                                  <b>{empresaEscolhida?.avaliacao} </b>
                                </h4>
                                <span className="text-secondary f-12 mt-auto">
                                  / 5
                                </span>
                              </div> */}
                              <div className="d-flex  gap-2 justify-content-center">
                                <h5>{empresa.avaliacao}</h5>
                                <span className="text-secondary">/ 5</span>
                              </div>
                              <div className="f-16 d-flex gap-2 justify-content-center">
                                <StarRating
                                  title={empresa.avaliacao}
                                  className="f-16 mx-auto"
                                  rating={empresa.avaliacao}
                                />
                              </div>
                            </div>
                          </a>
                        ))}
                    </>
                  ) : (
                    <div className="d-flex justify-content-start">
                      <ProfileCard />
                    </div>
                  )}
                </div>
              </div>
              <br />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-6 my-3">
            <h6 className="f-reg">
              <b>O que estão falando sobre {empresaEscolhida?.nomeEmpresa} </b>
            </h6>

            <br />
            <div className="card-sobre-empresa border-1 bg-white p-3">
              <b className="text-dark f-reg">Reclamações de clientes </b>

              {/* O que estão falando desta empresa, card */}
              {empresaEscolhida ? (
                <>
                  {empresaEscolhida?.length != 0 ? (
                    reclamacoesEmpresa
                      .slice(0, reclamacoesParaExibir)
                      .map((reclamacao, index) => (
                        <ReclamacaoItem key={index} reclamacao={reclamacao} />
                      ))
                  ) : (
                    <>
                      <center>
                        <br />
                        <br />
                        <i className="bi bi-megaphone f-24 text-secondary"></i>{" "}
                        <br />
                        <br />
                        <span className="text-secondary f-14 w-75">
                          Não há ainda reclamações ou avaliações para esta
                          empresa
                        </span>
                        <br />
                        <a href={`/reclamar/${empresaEscolhida?.slug}`}>
                          Seja o primeiro a avaliar
                        </a>
                      </center>
                      <br />
                    </>
                  )}
                  {reclamacoesEmpresa.length > reclamacoesParaExibir && (
                    <div className="text-center my-3">
                      <button className="btn btn-link" onClick={handleVerMais}>
                        Ver mais Avaliações
                      </button>
                    </div>
                  )}
                  {reclamacoesParaExibir > 5 && (
                    <div className="text-center my-3">
                      <button className="btn btn-link" onClick={handleVerMenos}>
                        Ver menos Avaliações
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {[...Array(reclamacoesParaExibir)].map((_, index) => (
                    <Comment key={index} className="w-100" />
                  ))}
                  {reclamacoesParaExibir > 5 && (
                    <div className="text-center my-3">
                      <button className="btn btn-link" onClick={handleVerMenos}>
                        Ver menos Avaliações
                      </button>
                    </div>
                  )}
                  {reclamacoesParaExibir <= 5 && (
                    <div className="text-center my-3">
                      <button className="btn btn-link" onClick={handleVerMais}>
                        Ver mais Avaliações
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            <br />
            <br />

            <Pub />
            <br />
          </div>
          <div className="col-12 col-sm-6 col-lg-3 my-3">
            <h6 className="f-reg">
              <b>Veja mais informações sobre {empresaEscolhida?.nomeEmpresa}</b>
            </h6>
            <br />
            <div className="card-sobre-empresa border-1 bg-white p-3">


              <b className="text-dark f-reg">Sobre</b>

              <div
                className="text-secondary f-14"
                dangerouslySetInnerHTML={{ __html: empresaEscolhida?.sobre || '' }}
              />
              <br />


              <center className="mt-2">
                <span className="text-secondary f-12">
                  Informações cadastradas pela empresa
                </span>
              </center>
              <hr />
              <center>
                <span className="d-flex gap-2 justify-content-center  f-14">
                  <i className="bi bi-calendar"></i> {empresaEscolhida?.quando}
                </span>
              </center>
            </div>
            <br />

            <div className="card-sobre-empresa border-1 bg-white p-3">
              <b className="text-dark f-reg">Informações de contacto</b>
              <br />
              <br />
              <b>Site:</b>
              <span className="p-2 info-empresa bg-light rounded-2 text-decoration-none">
                {" "}
                <a
                  target="__blank"
                  className=" text-decoration-none"
                  href={`https://${empresaEscolhida?.siteEmpresa}`}
                >
                  {empresaEscolhida?.siteEmpresa}
                </a>
              </span>
              <br />
              <br />
              <b>Whatsapp:</b>
              <span className="p-2 info-empresa bg-light rounded-2 text-decoration-none">
                {" "}
                <a
                  className=" text-decoration-none"
                  href={"tel:" + empresaEscolhida?.whatsapp}
                >
                  {empresaEscolhida?.whatsapp}
                </a>
              </span>
              <hr />
              <center>
                <span className="d-flex gap-3 justify-content-center  f-14">
                  <a href={"https://facebook.com/" + empresaEscolhida?.fb}>
                    {" "}
                    <i className="bi bi-facebook f-20"></i>{" "}
                  </a>
                  <a href={"https://instagram.com/" + empresaEscolhida?.insta}>
                    {" "}
                    <i className="bi bi-instagram f-20"></i>{" "}
                  </a>
                  <a href={"https://youtube.com/" + empresaEscolhida?.fb}>
                    {" "}
                    <i className="bi bi-youtube f-20"></i>{" "}
                  </a>
                </span>
              </center>

              <hr />
              <ProfileQRCode empresa={empresaEscolhida} />

            </div>

            <div className="quem-viu-sm">
              <br />
              <hr />
              <br />

              <br />
              <div className="card-sobre-empresa border-1 bg-white p-3">
                <b className="text-dark ">
                  Quem viu {empresaEscolhida?.nomeEmpresa} também viu:
                </b>
                <br />
                <br />

                <div className="listas-lojas mb-3  d-flex gap-3 overflow-x-auto listas-descontos">
                  {dadosEmpresas.length != 0 ? (
                    <>
                      {dadosEmpresas
                        .filter(
                          (empresa) => empresa._id !== empresaEscolhida?.slug
                        )
                        .map((empresa) => (
                          <a
                            key={empresa._id}
                            href={`/empresa/${empresa._id}`}
                            className="card-loja text-decoration-none text-dark text-center rounded-1 border-lightt p-3 shadow-sm"
                          >
                            <img
                              src={empresa.logo}
                              alt=""
                              className="logo-empresa"
                            />
                            <div className="bod">
                              <AbreviarTexto
                                texto={empresaEscolhida?.nomeEmpresa}
                                largura={"190"}
                              />

                              <p className="d-flex justify-content-center mt-1 my-auto gap-2 f-12">
                                <AbreviarTexto
                                  texto={empresaEscolhida?.enderecoEmpresa}
                                  largura={"300"}
                                  className="my-auto text-secondary"
                                ></AbreviarTexto>
                              </p>
                              <hr />

                              {/* <div className="d-flex gap-2 justify-content-center">
                                {empresaEscolhida?.avaliacao >= 5.0 &&
                                empresaEscolhida?.avaliacao <= 6.9 ? (
                                  <img
                                    src={regular}
                                    alt=""
                                    className="icon-empresa"
                                  />
                                ) : empresaEscolhida?.avaliacao >= 7.0 &&
                                  empresaEscolhida?.avaliacao <= 10.0 ? (
                                  <img
                                    src={otimo}
                                    alt=""
                                    className="icon-empresa"
                                  />
                                ) : empresaEscolhida?.avaliacao >= 3.0 &&
                                  empresaEscolhida?.avaliacao <= 4.9 ? (
                                  <img
                                    src={ruim}
                                    alt=""
                                    className="icon-empresa"
                                  />
                                ) : empresaEscolhida?.avaliacao <= 2.9 ? (
                                  <img
                                    src={naorecomendado}
                                    alt=""
                                    className="icon-empresa"
                                  />
                                ) : null}
                                <h4 className="f-reg my-auto">
                                  <b>{empresaEscolhida?.avaliacao} </b>
                                </h4>
                                <span className="text-secondary f-12 mt-auto">
                                  / 5
                                </span>
                              </div> */}
                              <div className="d-flex  gap-2 justify-content-center">
                                <h5>{empresa.avaliacao}</h5>
                                <span className="text-secondary">/ 5</span>
                              </div>
                              <div className="f-16 d-flex gap-2 justify-content-center">
                                <StarRating
                                  title={empresa.avaliacao}
                                  className="f-16 mx-auto"
                                  rating={empresa.avaliacao}
                                />
                              </div>
                            </div>
                          </a>
                        ))}
                    </>
                  ) : (
                    <div className="d-flex justify-content-start">
                      <ProfileCard />
                    </div>
                  )}
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>

      <div className="s">
        <Pub />

        <br />

        <Footer />
      </div>
    </div>
  );
};

export default PerfilEmpresa;
