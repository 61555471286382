
import gk from "../../imgs/gokside.png";
import pleno from "../../imgs/pleno.png";
import sdq from "../../imgs/logo_s.png";

export function Partners() {
  return (

    <div className="py-5 my-3 text-center bg-white partners text-">
      <center>
        <h2 className="f-reg">PARCEIROS</h2>
      </center>

      <br />
      <center className="flex-wrap gap-4 d-flex partners justify-content-center">
        <img src={gk} alt="" />
        <img src={pleno} alt="" />
        <img src={sdq} alt="" />
      </center>
    </div>
  )
}